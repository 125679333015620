export class SortableFilter {
  filterId: string;
  isVisibleInDenseMode: boolean;
  isRelevant: boolean;
  isIrrelevant: boolean;
  forceIndex: number | undefined;

  constructor(
    filterId: string,
    isVisibleInDenseMode: boolean,
    isRelevant: boolean,
    isIrrelevant: boolean,
    forceIndex: number | undefined,
  ) {
    this.filterId = filterId;
    this.isVisibleInDenseMode = isVisibleInDenseMode;
    this.isRelevant = isRelevant;
    this.isIrrelevant = isIrrelevant;
    this.forceIndex = forceIndex;
  }
}
