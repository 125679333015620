import type { Scale } from "./Scale.ts";

export class RoundingScale implements Scale {
  constructor(private readonly scale: Scale) {}

  toValue(unscaled: number): number {
    return this.scale.toValue(unscaled);
  }

  fromValue(scaled: number): number {
    return Math.round(this.scale.fromValue(scaled));
  }

  public get resizeObserver() {
    return undefined;
  }
}

export class DeJitteringScale implements Scale {
  private constructor(
    private readonly scale: Scale,
    private steps: number,
    private jitter: number = (0.5 * scale.toValue(steps)) / steps,
    private readonly strengths = [10000, 5000, 2500, 2000, 1000, 500, 250, 200, 100, 50, 25, 20, 10, 5, 2],
  ) {}

  public static deJitter(scale: Scale, min: number, max: number, strengths?: number[]) {
    return new DeJitteringScale(scale, max - min, undefined, strengths);
  }

  public adjustJitter(steps: number) {
    this.jitter *= this.steps / steps;
    this.steps = steps;
  }

  public get resizeObserver() {
    return new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.adjustJitter(entry.contentRect.width);
      }
    });
  }

  toValue(unscaled: number): number {
    return this.scale.toValue(unscaled);
  }

  fromValue(scaled: number): number {
    return this.deJitter(scaled);
  }

  private deJitter(scaled: number) {
    const value = this.unscaleRounded(scaled);
    const min = this.unscaledMin(scaled);
    const max = this.unscaledMax(scaled);

    /*                                                    */
    const smoothed = this.strengths
      /*                                                                                    */
      .filter((strength) => strength <= max)
      /*                                                             */
      .map(DeJitteringScale.softener(value))
      /*                                                   */
      .find((tmp) => min <= tmp && tmp <= max);

    return smoothed || value;
  }

  private unscaledMin(value: number) {
    return this.unscaleFloored(value - this.jitter);
  }

  private unscaledMax(value: number) {
    return this.unscaleFloored(value + this.jitter);
  }

  private unscaleRounded(scaled: number) {
    return Math.round(this.scale.fromValue(scaled));
  }

  private unscaleFloored(value: number) {
    return Math.floor(this.scale.fromValue(value));
  }

  private static softener(value: number) {
    return (strength: number) => Math.round(value / strength) * strength;
  }
}
