import { eventQBus } from "../types/EventQBus";
import { forEachElement } from "../util/Utils";
import { addFormChangeWatcher } from "./FilterDetailSheet";

function onClickOpen(event: Event) {
  const target = event.currentTarget as HTMLElement;
  const { filterId } = target?.dataset || {};

  eventQBus.emit("ftfind.filterSheet.open", (filterId && { filterId }) || {});
}

function onClickClose() {
  eventQBus.emit("ftfind.filterSheet.close");
}

function onChangeClose() {
  eventQBus.emit("ftfind.filterSheet.close");
}

function onClickDetailClose(event: Event) {
  const target = event.currentTarget as HTMLElement;
  const { filterId = "unknown", filterTitle } = target.dataset;

  eventQBus.emit("ftfind.filterSheet.closeDetail", {
    filterId,
    filterTitle,
  });
}

function createFormChangeWatcher(elem: HTMLButtonElement) {
  if (elem.form) {
    addFormChangeWatcher(elem.form, elem);
  }
}

function onFilterSectionLoaded() {
  forEachElement(".js_openFilterSheet", (elem) => elem.addEventListener("click", onClickOpen));
  forEachElement(".js_closeFilterSheet", (elem) => elem.addEventListener("click", onClickClose));
  forEachElement(".js_closeOnChange", (elem) => elem.addEventListener("change", onChangeClose));
  forEachElement(".js_closeDetailFilterSheet", (elem) => elem.addEventListener("click", onClickDetailClose));

  /*                                                                  */
  forEachElement(".js_closeDetailFilterSheet[type=submit]", createFormChangeWatcher);
}

export function registerFilterSheetEventListeners() {
  eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
  eventQBus.on("heureka.filterSection.loadAborted", onFilterSectionLoaded);
}
