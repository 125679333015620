import { device } from "@otto-ec/global-resources/device";

declare global {
  interface Window {
    safari?: unknown;
  }
}
type ArrayPredicate<T> = (elem: T, index: number, obj: T[]) => unknown;

export function cloneElement<E extends HTMLElement>(elem: E) {
  return elem.cloneNode() as E;
}

export function cloneElementDeep<E extends HTMLElement>(elem: E) {
  return elem.cloneNode(true) as E;
}

export function getElementById<E extends HTMLElement>(id?: string): E | undefined {
  return (id && (document.getElementById(id) as E)) || undefined;
}

export function element<E extends HTMLElement>(selector: string, rootElement?: ParentNode | null) {
  return (rootElement || document).querySelector<E>(selector);
}

export function allElements<E extends HTMLElement>(selector: string, rootElement?: ParentNode | null) {
  return (rootElement || document).querySelectorAll<E>(selector);
}

export function forEachItem<T>(
  arrayLike: ArrayLike<T> | HTMLCollectionBase,
  callback: (elem: T, index: number) => void,
) {
  Array.prototype.forEach.call(arrayLike, callback);
}

export function forEachElement<E extends HTMLElement>(
  selectors: string,
  callback: (elem: E, index: number) => void,
  rootElement?: ParentNode | null,
) {
  forEachItem(allElements<E>(selectors, rootElement), callback);
}

export function mapItems<T, R>(arrayLike: ArrayLike<T>, callback: (elem: T, index: number) => R): R[] {
  return Array.prototype.map.call<ArrayLike<T>, ((elem: T, index: number) => R)[], R[]>(arrayLike, callback);
}

export function mapElements<T, E extends HTMLElement>(
  selectors: string,
  callback: (elem: E, index: number) => T,
  rootElement?: ParentNode | null,
): T[] {
  return mapItems(allElements<E>(selectors, rootElement), callback);
}

export function filterItems<T>(arrayLike: ArrayLike<T>, predicate: ArrayPredicate<T>): T[] {
  return Array.prototype.filter.call<ArrayLike<T>, ArrayPredicate<T>[], T[]>(arrayLike, predicate);
}

export function filterElements<E extends HTMLElement>(
  selectors: string,
  predicate: (elem: E) => unknown,
  rootElement?: ParentNode | null,
): E[] {
  return filterItems(allElements(selectors, rootElement), predicate);
}

export function findItem<T>(arrayLike: ArrayLike<T>, predicate: ArrayPredicate<T>): T | undefined {
  return Array.prototype.find.call<ArrayLike<T>, ArrayPredicate<T>[], T>(arrayLike, predicate);
}

export function toArray<E extends HTMLElement>(htmlCollection?: HTMLCollectionBase) {
  return htmlCollection ? Array.from(htmlCollection as unknown as ArrayLike<E>) : [];
}

export function childElements(source: HTMLElement | null) {
  return source && toArray(source.children);
}

export function moveAllChildren(
  source: HTMLElement | null,
  destination: HTMLElement | null,
  onlyEmptyDestination?: boolean | undefined,
) {
  appendAll(childElements(source), destination, onlyEmptyDestination);
}

export function appendAll(
  source: HTMLElement[] | null | undefined,
  destination: HTMLElement | null,
  onlyEmptyDestination?: boolean | undefined,
) {
  if (source && destination) {
    /*                                                          */
    /*                                                                                                       */
    /*                                                                          */
    if (onlyEmptyDestination) {
      /*                                                                                               */
      if (destination.children.length == 0) {
        source.forEach(destination.appendChild.bind(destination));
      }
    } else {
      source.forEach(destination.appendChild.bind(destination));
    }
  }
}

export function prependElement(source: HTMLElement | null | undefined, destination: HTMLElement | null) {
  if (source && destination) {
    destination.prepend(source);
  }
}

export function move(elem: HTMLElement | null, destination: HTMLElement | null, force = true) {
  /*                                                  */
  if (elem && destination && (force || destination.children.length == 0)) {
    /*                                                          */
    destination.appendChild(elem);
  } else {
    /*                             */
    elem?.remove();
  }
}

export function clone<E extends HTMLElement>(elem: E, suffix = "--cloned") {
  const clone = cloneElementDeep<E>(elem);
  forEachElement<HTMLElement>("[id]", (elem) => addSuffixTo(elem, "id", suffix), clone);
  forEachElement<HTMLElement>("[for]", (elem) => addSuffixTo(elem, "for", suffix), clone);
  return clone;
}

function addSuffixTo(elem: HTMLElement, attributeName: string, suffix: string) {
  const forAttr = elem.getAttribute(attributeName);
  if (forAttr) {
    elem.setAttribute(attributeName, forAttr + suffix);
  }
}

export function clear(elem: HTMLElement | undefined) {
  if (elem) {
    while (elem.firstChild) {
      elem.removeChild(elem.firstChild);
    }
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function watchEvent<K extends keyof HTMLElementEventMap, K2 extends keyof HTMLElementEventMap>(
  watchTarget: HTMLElement,
  eventName: K,
  listener: (event: HTMLElementEventMap[K], abortController: AbortController) => void,
  resetEventName?: K2,
  resetListener?: (event: HTMLElementEventMap[K2]) => void,
) {
  addWatcher<K>(watchTarget, eventName, listener);

  if (resetEventName && resetListener) {
    watchTarget.addEventListener(
      resetEventName,
      (event) => {
        resetListener(event);
        addWatcher<K>(watchTarget, eventName, listener);
      },
      {
        passive: true,
      },
    );
  }
}

function addWatcher<K extends keyof HTMLElementEventMap>(
  watchTarget: HTMLElement,
  eventName: K,
  listener: (event: HTMLElementEventMap[K], abortController: AbortController) => void,
) {
  const abortController = new AbortController();
  const signal = abortController.signal;

  watchTarget.addEventListener(eventName, (event) => listener(event, abortController), {
    passive: true,
    signal,
  });
}

export function isAppEnvironment(): boolean {
  return document.documentElement.classList.contains("app");
}

export function isSamsungBrowser() {
  return navigator.userAgent.match("/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i");
}

export function isDesktopDevice() {
  return device.type === "desktop";
}

export function isProductList(): boolean {
  return !!document.querySelector('[data-pagecluster="Produktliste"]');
}

export function isSearchResultPage(): boolean {
  return !!document.querySelector('[data-pagecluster="Suchergebnisseite"]');
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
