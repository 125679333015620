import type { TrackingLabels } from "./TrackingLabels";
import type { Action, EventMergeId, Feature } from "./Tracking";
import { type DataContainer, tracking } from "@otto-ec/tracking-bct";

class Tracker {
  private debug = false;

  enableDebug(): void {
    this.debug = true;
  }

  static convertLabelsToDataContainer(labels: Partial<TrackingLabels>): DataContainer {
    const dataContainer: DataContainer = {};

    Object.keys(labels).forEach((key) => {
      const value = labels[key as keyof TrackingLabels];
      if (value !== undefined) {
        dataContainer[key] = Array.isArray(value) ? value : [value];
      }
    });

    return dataContainer;
  }

  private logEvent(name: string, items: Array<object | string>) {
    if (this.debug) {
      const expandedItems = items.map((item) => {
        if (typeof item === "object" && item !== null) {
          return JSON.stringify(item, null, 2);
        }
        return item;
      });
      console.log(name, ...expandedItems);
    }
  }

  submitEvent(labels: Partial<TrackingLabels>): void {
    this.logEvent("submitEvent", [labels]);
    tracking.submitEvent(Tracker.convertLabelsToDataContainer(labels), undefined);
  }

  submitAction(labels: Partial<TrackingLabels>, action: Action): void {
    this.logEvent("submitAction", [labels, action]);
    tracking.submitEvent(Tracker.convertLabelsToDataContainer(labels), action);
  }

  submitMoveAction(labels: Partial<TrackingLabels>, action: Action): void {
    this.logEvent("submitMoveAction", [labels, action]);
    tracking.submitMove(Tracker.convertLabelsToDataContainer(labels), action);
  }

  addToEvent(labels: Partial<TrackingLabels>, eventMergeId: EventMergeId): void {
    this.logEvent("addToEvent", [labels, eventMergeId]);
    tracking.submitEventMerge(eventMergeId, Tracker.convertLabelsToDataContainer(labels));
  }

  addActionToEvent(action: Action, eventMergeId: EventMergeId): void {
    this.logEvent("addActionToEvent", [action, eventMergeId]);
    tracking.submitEventMerge(eventMergeId, {}, action);
  }

  addToPageImpression(labels: Partial<TrackingLabels>): void {
    this.logEvent("addToPageImpression", [labels]);
    tracking.submitMerge(Tracker.convertLabelsToDataContainer(labels));
  }

  addFeaturesToPageImpression(features: Feature[]): void {
    this.logEvent("addFeaturesToPageImpression", [features]);
    tracking.submitMerge({}, features);
  }

  trackOnNextPageImpression(labels: Partial<TrackingLabels>): void {
    this.logEvent("trackOnNextPageImpression", [labels]);
    tracking.trackOnNextPageImpression(Tracker.convertLabelsToDataContainer(labels));
  }
}

const tracker = new Tracker();
export default tracker;
