<script lang="ts">
  interface Props {
    isPrefix: boolean;
    text: string;
  }

  let { isPrefix, text }: Props = $props();
</script>

<small class="pl_input__note {isPrefix ? ' pl_input__prefix' : ' pl_input__suffix'}">{text}</small>
