import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { FilterSection } from "./FilterSection";
import type { Reloadable } from "../util/Reloadable";
import { getElementById } from "../util/Utils";

const FILTER_SECTION_WRAPPER_ID = "heureka_filterDynamicSection";
const FILTER_SECTION_WRAPPER_CLASS = "reptile_sidebar__filterSection";

export class FilterSectionWrapper implements Reloadable {
  static readonly factory = getElementById(FILTER_SECTION_WRAPPER_ID)
    ? HeurekaElementFactory.byId(FILTER_SECTION_WRAPPER_ID, FilterSectionWrapper)
    : HeurekaElementFactory.byClass(FILTER_SECTION_WRAPPER_CLASS, FilterSectionWrapper);

  /*               */
  constructor(readonly elem: HTMLElement) {}

  /*                  */
  static async template() {
    return FilterSectionWrapper.factory.picking();
  }

  /*               */

  prepare(fragment: DocumentFragment, initial: boolean) {
    FilterSection.prepare(fragment, initial);
  }
}
