import type { Topics } from "../types/EventQBus";
import { eventQBus } from "../types/EventQBus";

declare global {
  interface HTMLElement {
    partialLock: PartialLock;
  }
}

const PARTIAL_LOCK_CLASS = "heureka_partialLock";
const PARTIAL_LOCK_OWNER_CLASS = "heureka_partialLock__owner";
const PARTIAL_LOCK_OWNER_SELECTOR = `.${PARTIAL_LOCK_OWNER_CLASS}`;

export default class PartialLock {
  private constructor(
    private readonly childSelector: string,
    private readonly trapAction: (lockOwner: HTMLElement) => boolean,
  ) {}

  static eventlistener: EventListener;
  static listenerOptions = {
    capture: true,
  };

  public static create(
    anchor: HTMLElement,
    childSelector: string,
    trapAction: (lockOwner: HTMLElement) => boolean,
    {
      lockEvent = "change",
      unlockTopics,
    }: { lockEvent?: keyof HTMLElementEventMap; unlockTopics?: (keyof Topics)[] } = {},
  ): PartialLock {
    if (!anchor.partialLock) {
      const partialLock = new PartialLock(childSelector, trapAction);
      anchor.partialLock = partialLock;
      anchor.addEventListener(lockEvent, partialLock.onLockEvent.bind(partialLock, anchor), {
        passive: true,
      });
      document.removeEventListener("click", PartialLock.eventlistener, PartialLock.listenerOptions);
      PartialLock.eventlistener = partialLock.onTrapEvent.bind(partialLock, anchor);
      document.addEventListener("click", PartialLock.eventlistener, PartialLock.listenerOptions);
      if (unlockTopics) {
        unlockTopics.forEach((topic) => {
          eventQBus.on(topic, () => partialLock.onUnlockEvent(anchor));
        });
      }
    }
    return anchor.partialLock;
  }

  private onUnlockEvent(container: HTMLElement) {
    const lockOwner = PartialLock.getLockOwner(container);
    if (lockOwner) {
      PartialLock.toggleLock(container, lockOwner, false);
    }
  }

  private onLockEvent(container: HTMLElement, event: Event) {
    const target = this.getTargetedChild(event);

    if (target) {
      PartialLock.toggleLock(container, target, true);
    }
  }

  private onTrapEvent(container: HTMLElement, event: Event) {
    const target = this.getTargetedChild(event);

    if (target && PartialLock.isLocked(container) && PartialLock.isLockedChild(target)) {
      const lockOwner = PartialLock.getLockOwner(container);
      if (lockOwner && this.trapAction(lockOwner)) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        PartialLock.toggleLock(container, target, false);
      }
    }
  }

  private getTargetedChild(event: Event) {
    return (event.target as HTMLElement | null)?.closest<HTMLElement>(this.childSelector) || null;
  }

  private static getLockOwner(container: HTMLElement) {
    return container.querySelector<HTMLElement>(PARTIAL_LOCK_OWNER_SELECTOR);
  }

  private static toggleLock(container: HTMLElement, lockOwner: HTMLElement, force: boolean) {
    container.classList.toggle(PARTIAL_LOCK_CLASS, force);
    lockOwner.classList.toggle(PARTIAL_LOCK_OWNER_CLASS, force);
  }

  private static isLocked(elem: HTMLElement) {
    return elem.classList.contains(PARTIAL_LOCK_CLASS);
  }

  private static isLockedChild(elem: HTMLElement) {
    return !elem.classList.contains(PARTIAL_LOCK_OWNER_CLASS);
  }
}
