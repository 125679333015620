import type { TrackingTopics } from "../tracking/Tracking";
import type { SheetTopics } from "../filtersheet/FilterSheetTopics";
import type { OrchestrationTopics } from "../multifiltering/FilterTypes";
import type { PaliTopics } from "./PaliTopics";
import type { SliderTopics } from "../filter/slider/SliderTopics";
import type { SearchBoxTopics } from "../filter/SearchableFacet";
import { eventQBus as bus } from "@otto-ec/global-resources/event-q-bus";

export const eventQBus = bus.with<Topics>();

type NonHistoryTopics = TrackingTopics &
  HistoryTopics &
  SheetTopics &
  SliderTopics &
  OrchestrationTopics &
  PaliTopics &
  SearchBoxTopics;

type HistoryMergeType = {
  topic: keyof NonHistoryTopics;
  data: Record<string, unknown>;
};

type HistoryPushType = {
  topic: keyof NonHistoryTopics;
  data: Record<string, unknown>;
  ephemeral?: boolean;
};

export type HistoryTopics = {
  "ftfind.history.merge": [HistoryMergeType];
  "ftfind.history.push": [HistoryPushType];
  "ftfind.history.shift": [{ force?: boolean }];
};

export type Topics = NonHistoryTopics & HistoryTopics;
