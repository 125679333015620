import { HeurekaElementFactory } from "../util/HeurekaElementFactory";

const SELECTED_VALUE_CLASS = `heureka_filterTitle__selectedValueItem`;
const SELECTED_VALUE_FAVORITE_ICON_CLASS = ".heureka_favoriteIcon";

export class FilterTitleSelectedValue {
  static readonly factory = HeurekaElementFactory.byElement("li", SELECTED_VALUE_CLASS, FilterTitleSelectedValue);

  /*               */
  constructor(readonly elem: HTMLLIElement) {}

  /*                  */

  /*               */

  /*                       */

  get tsFeatureRef(): string | undefined {
    return this.elem.dataset.tsFeatureRef;
  }

  get favoriteIcon(): HTMLElement | null {
    return this.elem.querySelector(SELECTED_VALUE_FAVORITE_ICON_CLASS);
  }

  set favoriteIconVisibility(visible: boolean) {
    if (this.favoriteIcon) {
      this.favoriteIcon.hidden = !visible;
    }
  }
}
