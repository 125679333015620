import { SortingInput } from "./SortingInput";
import { SortingForm } from "./SortingForm";
import { SortingSubmitAction } from "./SortingSubmitAction";
import { SmartSorting } from "./SmartSorting";

export function registerSorting() {
  SmartSorting.register();
  SortingInput.register();
  SortingForm.register();
  SortingSubmitAction.register();
}
