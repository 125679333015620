import { eventQBus } from "../../types/EventQBus";
import { fireFilterSubmit, unlockSubmitButtons } from "../FilterFormActions";
import type { TrackingLabels } from "../../tracking/TrackingLabels";
import DelayedAction from "../../util/DelayedAction";
import { FacetValue } from "../FacetValue";
import { element, isDesktopDevice } from "../../util/Utils";
import { HeurekaElementFactory } from "../../util/HeurekaElementFactory";
import tracker from "../../tracking/Tracker";

const SLIDER_HIDDEN_INPUT_FIELD_CLASS = "ts_heureka_sliderHiddenInput";
const SLIDER_HIDDEN_INPUT_FIELD_SELECTOR = `.${SLIDER_HIDDEN_INPUT_FIELD_CLASS}`;
const PALI_SLIDER_CLASS = "heureka_slider";
const PALI_SLIDER_SELECTOR = `.${PALI_SLIDER_CLASS}`;
const SLIDER_CLASS = "ts_heureka_slider";
const SLIDER_SELECTOR = `.${SLIDER_CLASS}`;

const MINMAX_FIELD_SELECTOR = "input[type=number]";
const MINMAX_RANGE_SELECTOR = "input[type=range]";

export class Slider {
  static readonly factory = HeurekaElementFactory.byClass(SLIDER_CLASS, Slider);

  /*               */
  constructor(
    readonly elem: HTMLElement,
    readonly form: HTMLFormElement | null = elem.closest("form"),
    readonly slider: HTMLElement | null = elem.querySelector(PALI_SLIDER_SELECTOR),
    readonly sliderField: HTMLInputElement | null = elem.querySelector<HTMLInputElement>(
      SLIDER_HIDDEN_INPUT_FIELD_SELECTOR,
    ) || null,
    readonly minField: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(MINMAX_FIELD_SELECTOR)[0] ||
      null,
    readonly minRangeSelector: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(
      MINMAX_RANGE_SELECTOR,
    )[0] || null,
    readonly maxField: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(MINMAX_FIELD_SELECTOR)[1] ||
      null,
    readonly maxRangeSelector: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(
      MINMAX_RANGE_SELECTOR,
    )[1] || null,
  ) {}

  /*                  */
  static fromId(sliderId: string, root?: ParentNode) {
    return Slider.factory.declare(element(`#${sliderId}`, root)?.closest(SLIDER_SELECTOR));
  }

  /*               */

  static register() {
    eventQBus.on("heureka.sliders.initialised", Slider.#initAll);
  }

  static #initAll() {
    Slider.factory.all().forEach((slider) => slider.init());
  }

  static init({ id }: { id: string }) {
    Slider.fromId(id)?.init();
  }

  private init() {
    if (this.elem.dataset.heurekaSliderInitialized) {
      /*                   */
      return;
    }
    this.elem.dataset.heurekaSliderInitialized = "true";

    if (this.slider) {
      this.initializeAutoSubmit();

      this.initializeInputField(this.minField, this.minRangeSelector, 0);
      this.initializeSliderRange();
      this.initializeInputField(this.maxField, this.maxRangeSelector, 1);
    }
    return this;
  }

  private initializeAutoSubmit() {
    if (this.form && this.slider) {
      const delayedAction = DelayedAction.create(this.form, () => this.fireSliderAutoSubmit("adopt_delayed"));

      const doCancel = delayedAction.cancel.bind(delayedAction);
      const doDelay = delayedAction.delay.bind(delayedAction, {});
      const doDelayNoWait = delayedAction.delay.bind(delayedAction, {
        delayInMillis: 0,
        actionToPerform: () => this.fireSliderAutoSubmit(), /*                          */
      });
      const doThrottle = delayedAction.throttle.bind(delayedAction, {
        throttleInMillis: 50,
      });
      const options = { passive: true };

      /*                                                                                                  */
      this.slider.addEventListener("touchstart", doCancel, options);
      this.slider.addEventListener("mousedown", doCancel, options);
      this.slider.addEventListener("touchend", doDelay, options);
      this.slider.addEventListener("mouseup", doDelay, options);

      this.slider.addEventListener("focusin", doCancel, options);
      this.slider.addEventListener("focusout", doDelayNoWait, options);

      /*                                                                                                    */
      /*                                                                                                    */
      /*                                               */
      if (isDesktopDevice()) {
        this.form.addEventListener(
          "mousedown",
          (event) => {
            if (FacetValue.isFacetValueUserInteraction(event)) doThrottle();
          },
          options,
        );
      }

      this.form.addEventListener(
        "click",
        (event) => {
          if (event.target instanceof HTMLInputElement && event.target.type !== "range") doCancel();
        },
        options,
      );

      this.form.addEventListener("filterSubmit", doCancel, options);
      this.form.addEventListener("reset", doCancel, options);
      this.form.addEventListener("submit", () => (this.autoSubmitAllowed = false), options);
    }
  }

  private initializeInputField(
    inputField: HTMLInputElement | undefined | null,
    singleSlider: HTMLInputElement | undefined | null,
    limitIndex: number,
  ) {
    if (this.slider && inputField) {
      const limitKey = limitIndex === 0 ? "heurekaSliderInitialMinValue" : "heurekaSliderInitialMaxValue";
      const limitValue = this.slider.dataset[limitKey];
      const limitType = limitIndex === 0 ? "min" : "max";
      const { facetName } = this.slider.dataset;
      if (limitValue) {
        inputField.value = limitValue;
        inputField.dispatchEvent(new Event("change"));
      }
      if (singleSlider) {
        singleSlider.addEventListener(
          "change",
          () => this.onInputFieldChanged(limitIndex, inputField, `slider_${limitType}`),
          {
            passive: true,
          },
        );
      }
      inputField.addEventListener("change", () => this.onInputFieldChanged(limitIndex, inputField, "interval"), {
        passive: true,
      });
      if (facetName) {
        inputField.addEventListener("focusin", this.trackFocus(`click_${limitType}`, facetName), {
          passive: true,
        });
        inputField.addEventListener("input", this.trackDelete(`delete_${limitType}`, facetName), {
          passive: true,
        });
      }
    }
  }

  private initializeSliderRange() {
    const sliderRange = this.slider?.querySelector<HTMLDivElement>(MINMAX_RANGE_SELECTOR)?.closest("div");
    sliderRange?.addEventListener(
      "click",
      () => {
        if (this.minField && this.minField.value != this.slider?.dataset.sliderMinVal) {
          this.onInputFieldChanged(0, this.minField, "slider_min");
        }
        if (this.maxField && this.maxField.value != this.slider?.dataset.sliderMaxVal) {
          this.onInputFieldChanged(1, this.maxField, "slider_max");
        }
      },
      {
        passive: true,
      },
    );
  }

  private fireSliderAutoSubmit(filterMethod?: string) {
    /*                                                         */
    if (this.form && this.form.closest(".heureka_filterTitle__values") && this.autoSubmitAllowed) {
      if (filterMethod) {
        this.form.dataset.tsFeatureFilterMethod = filterMethod;
      }
      this.autoSubmitAllowed = false;
      return fireFilterSubmit(this.form);
    }
    return false;
  }

  private onInputFieldChanged(
    limitIndex: number,
    target: HTMLInputElement,
    filterMethod: TrackingLabels["san_FilterMethod"],
  ) {
    this.updateSliderFromInputField(limitIndex, target, filterMethod);
  }

  private updateSliderFromInputField(
    limitIndex: number,
    target: HTMLInputElement,
    filterMethod: TrackingLabels["san_FilterMethod"],
  ) {
    const { sliderField } = this;
    if (sliderField && this.form && this.slider) {
      this.autoSubmitAllowed = true;
      const oldFieldValue = sliderField.value;
      const newValue = Number(target.value || "_");

      /*                                    */
      Slider.exchangeLimit(sliderField, limitIndex, newValue);

      /*                                                                  */
      if (oldFieldValue !== sliderField.value) {
        sliderField.checked = true;
        /*                                                                                 */
        unlockSubmitButtons.bind(this.form)();
        sliderField.dataset.heurekaSliderLastMethod = filterMethod;
        sliderField.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }
  }

  /*                       */

  get minLabel(): string {
    return this.slider?.dataset.sliderMinLabel || "";
  }

  get maxLabel(): string {
    return this.slider?.dataset.sliderMaxLabel || "";
  }

  get minValue(): string {
    return this.slider?.dataset.sliderMinVal || "";
  }

  get maxValue(): string {
    return this.slider?.dataset.sliderMaxVal || "";
  }

  get facetName(): string {
    return this.slider?.dataset.facetName || "";
  }

  get initialMinValue(): string {
    return this.slider?.dataset.heurekaSliderInitialMinValue || "";
  }

  get initialMaxValue(): string {
    return this.slider?.dataset.heurekaSliderInitialMaxValue || "";
  }

  get availablePreselects(): string {
    return this.slider?.dataset.heurekaSliderAvailablePreselects || "";
  }

  get logarithmic(): string {
    return this.slider?.dataset.sliderLogarithmic || "";
  }

  get delayedActionMillis(): string {
    return this.slider?.dataset.delayedActionMillis || "";
  }

  get showDefaultPlaceholders(): string {
    return this.slider?.dataset.sliderShowDefaultPlaceholders || "";
  }

  get focusFloatsBothLabels(): string {
    return this.slider?.dataset.sliderFocusFloatsBothLabels || "";
  }

  get hideSlider(): string {
    return this.slider?.dataset.hideSlider || "";
  }

  get id(): string {
    return this.slider?.id || "";
  }

  /*                               */
  /*                     */
  /*                                                                                                 */
  /*  */
  /*           */
  /**/

  set autoSubmitAllowed(value: boolean) {
    if (this.form) this.form.dataset["autoSubmit"] = value ? "allowed" : "forbidden";
  }

  get autoSubmitAllowed(): boolean {
    return this.form?.dataset["autoSubmit"] !== "forbidden";
  }

  /*       */

  private trackFocus(
    sanFacetActivity: TrackingLabels["san_FacetActivity"],
    sanFacetActivityType: TrackingLabels["san_FacetActivityType"],
  ): EventListener {
    return () => {
      tracker.submitEvent({
        san_FacetActivity: sanFacetActivity,
        san_FacetActivityType: sanFacetActivityType,
      });
    };
  }

  private trackDelete(
    sanFacetActivity: TrackingLabels["san_FacetActivity"],
    sanFacetActivityType: TrackingLabels["san_FacetActivityType"],
  ): EventListener {
    return (event) => {
      const target = event.target;
      if (target instanceof HTMLInputElement && !target.value) {
        tracker.submitEvent({
          san_FacetActivity: sanFacetActivity,
          san_FacetActivityType: sanFacetActivityType,
        });
      }
    };
  }

  private static exchangeLimit(input: HTMLInputElement, limitIndex: number, limit: number) {
    const oldLowerLimit = Number((input.value || "_,_").split(",").at(0));
    const oldUpperLimit = Number((input.value || "_,_").split(",").at(1));

    const newLowerLimit = limitIndex == 0 ? limit : oldLowerLimit;
    const newUpperLimit = limitIndex == 1 ? limit : oldUpperLimit;

    const lowerLimit = isNaN(newLowerLimit) ? "_" : newLowerLimit;
    const upperLimit = isNaN(newUpperLimit) ? "_" : newUpperLimit;
    input.value = lowerLimit + "," + upperLimit;
    /*                                                                                                                  */
    input.defaultChecked = false;
  }

  private static exchangeLimits(input: HTMLInputElement, newLowerLimit: number, newUpperLimit: number) {
    input.value = (isNaN(newLowerLimit) ? "_" : newLowerLimit) + "," + (isNaN(newUpperLimit) ? "_" : newUpperLimit);
  }
}
