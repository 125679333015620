export class SliderElement {
  id: string;

  numberInputWrapper: HTMLDivElement;

  numberInputMinContainer: HTMLDivElement;

  numberInputMin: HTMLInputElement;

  numberInputMinLabel: HTMLLabelElement;

  numberInputMaxContainer: HTMLDivElement;

  numberInputMax: HTMLInputElement;

  numberInputMaxLabel: HTMLLabelElement;

  rangeInputWrapper: HTMLDivElement;

  inverseLeft: HTMLDivElement;

  range: HTMLDivElement;

  inverseRight: HTMLDivElement;

  thumbMin: HTMLSpanElement;

  thumbMax: HTMLSpanElement;

  rangeInputMin: HTMLInputElement;

  rangeInputMax: HTMLInputElement;

  constructor(
    id: string,
    numberInputWrapper: HTMLDivElement,
    numberInputMinContainer: HTMLDivElement,
    numberInputMin: HTMLInputElement,
    numberInputMinLabel: HTMLLabelElement,
    numberInputMaxContainer: HTMLDivElement,
    numberInputMax: HTMLInputElement,
    numberInputMaxLabel: HTMLLabelElement,
    rangeInputWrapper: HTMLDivElement,
    inverseLeft: HTMLDivElement,
    range: HTMLDivElement,
    inverseRight: HTMLDivElement,
    thumbMin: HTMLSpanElement,
    thumbMax: HTMLSpanElement,
    rangeInputMin: HTMLInputElement,
    rangeInputMax: HTMLInputElement,
  ) {
    this.id = id;
    this.numberInputWrapper = numberInputWrapper;
    this.numberInputMinContainer = numberInputMinContainer;
    this.numberInputMin = numberInputMin;
    this.numberInputMinLabel = numberInputMinLabel;
    this.numberInputMaxContainer = numberInputMaxContainer;
    this.numberInputMax = numberInputMax;
    this.numberInputMaxLabel = numberInputMaxLabel;
    this.rangeInputWrapper = rangeInputWrapper;
    this.inverseLeft = inverseLeft;
    this.range = range;
    this.inverseRight = inverseRight;
    this.thumbMin = thumbMin;
    this.thumbMax = thumbMax;
    this.rangeInputMin = rangeInputMin;
    this.rangeInputMax = rangeInputMax;
  }

  /**
 *
 *
 *
 *
 */
  getNumberInputMinValue(): string {
    return this.numberInputMin.value === "" ? this.numberInputMin.placeholder : this.numberInputMin.value;
  }

  getNumberInputMaxValue(): string {
    return this.numberInputMax.value === "" ? this.numberInputMax.placeholder : this.numberInputMax.value;
  }
}
