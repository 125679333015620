import {
  addFeaturesToPageImpressionTracking,
  clearStatus,
  dereference,
  deriveAction,
  deriveCommonAction,
  type ElementPredicate,
  findFeatures,
  getStatus,
  type IncludeFeatureCallback,
  or,
  readLabel,
  readLegacyLabels,
  submitActionTracking,
  submitMoveActionTracking,
  updateLabel,
  updateLabels,
  updateStatus,
} from "../tracking/FeatureTracking";
import type { ActionName, EventMergeId, FeatureName, FeatureStatus } from "../tracking/Tracking";
import { forEachElement } from "../util/Utils";
import { isChanged } from "./FilterFormActions";
import { onBreakpointChange } from "../util/Breakpoint";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import type { ExtendedChangeEvent } from "./SubmitOnChange";
import { eventQBus } from "../types/EventQBus";
import tracker from "../tracking/Tracker";

export const FILTER_FEATURES: FeatureName[] = ["Filter-Overview", "Filter", "Facet", "Facet-Value"];

/**
 *
 *
 *
 *
 */
function determineStatus(elem: HTMLInputElement): FeatureStatus {
  switch (elem.getAttribute("type")) {
    case "submit":
    case "text":
      return !elem.value || elem.value === "_,_" ? "predeselected" : "preselected";

    case "radio":
    case "checkbox":
      return elem.checked ? "preselected" : "predeselected";

    default:
      return "changed";
  }
}

/**
 *
 *
 *
 *
 */
function isDefaultActive(elem: HTMLInputElement): boolean {
  const type = elem.type ? elem.type : elem.getAttribute("type");
  switch (type) {
    case "text":
      return !!elem.defaultValue && elem.defaultValue !== "_,_";

    case "radio":
    case "checkbox":
      return elem.defaultChecked;

    default:
      return false;
  }
}

/**
 *
 *
 *
 *
 */
function isSingleSelect(elem: HTMLInputElement) {
  const type = elem.type ? elem.type : elem.getAttribute("type");
  return type === "radio";
}

/**
 *
 *
 *
 *
 */
export function hasPreStatus(elem: HTMLElement): boolean {
  const status = getStatus(elem);
  return status === "preselected" || status === "predeselected";
}

/**
 *
 *
 *
 *
 */
export function trackedFacetValueOld(value: string): string {
  const [min, max] = value.split(",");
  if (min && max) {
    if (min === "_") {
      return `bis-${max}`;
    }
    if (max === "_") {
      return `ab-${min}`;
    }
    return `${min}-${max}`;
  }
  return value;
}

/**
 *
 *
 *
 *
 */
export function trackedFacetValue(value: string): string {
  const [min, max] = value.split(",");
  return min && max ? `${min}-${max}` : value;
}

/**
 *
 *
 *
 *
 */
export function trackedFilterMethod(
  elem: HTMLInputElement | HTMLButtonElement,
): TrackingLabels["san_FilterMethod"] | undefined {
  if (elem.dataset.heurekaSliderLastMethod) {
    return <TrackingLabels["san_FilterMethod"]>elem.dataset.heurekaSliderLastMethod;
  }
  const type = elem.type ? elem.type : elem.getAttribute("type");
  switch (type) {
    case "number":
      return "interval";

    case "radio":
      return "radio_button";

    case "checkbox": {
      if (elem.classList.contains("js-pl_switch")) {
        return "switch";
      } else {
        return "checkbox";
      }
    }

    default:
      return undefined;
  }
}

/**
 *
 *
 *
 *
 */
export function updateFacetValueLabel(elem: HTMLInputElement, reset?: boolean) {
  /*                                                                                  */
  /*                                       */
  const type = elem.type ? elem.type : elem.getAttribute("type");
  if (type === "text") {
    updateLabel(elem, "san_FacetValue", trackedFacetValueOld((reset && elem.defaultValue) || elem.value));
  }
  /*                                                                                 */
  if (type === "radio" && elem.value.includes(",")) {
    updateLabel(elem, "san_FacetValue", trackedFacetValue((reset && elem.defaultValue) || elem.value));
  }
}

/**
 *
 *
 *
 *
 */
function updateFilterMethodLabel(elem: HTMLInputElement, reset?: TrackingLabels["san_FilterMethod"] | boolean) {
  updateLabel(
    elem,
    "san_FilterMethod",
    reset ? (typeof reset === "string" ? reset : undefined) : trackedFilterMethod(elem),
  );
}

/**
 *
 *
 *
 *
 *
 */
function persistTrackedStatus(
  elem: HTMLInputElement,
  tsFeatureFilterMethod?: TrackingLabels["san_FilterMethod"],
  tsAdditionalLabels?: Partial<TrackingLabels>,
) {
  /*                                                                                             */
  /*                                                                                                           */

  updateFacetValueLabel(elem);
  if (tsFeatureFilterMethod) {
    updateFilterMethodLabel(elem, tsFeatureFilterMethod);
  }
  if (tsAdditionalLabels) {
    updateLabels(elem, tsAdditionalLabels);
  }

  switch (determineStatus(elem)) {
    case "preselected":
      updateStatus(elem, "added", 2, (oldStatus, newStatus) => {
        return oldStatus === "deleted" || oldStatus === "changed" ? "changed" : newStatus;
      });
      return;
    case "predeselected":
      updateStatus(elem, "deleted", 2, (oldStatus, newStatus) => {
        return oldStatus === "added" || oldStatus === "changed" ? "changed" : newStatus;
      });
      return;
    default:
      updateStatus(elem, "changed", 2);
      return;
  }
}

/**
 *
 *
 *
 *
 */
function persistPreSubmitTrackedStatus(
  elem: HTMLInputElement,
  tsFeatureFilterMethod?: TrackingLabels["san_FilterMethod"],
) {
  updateFacetValueLabel(elem);
  if (tsFeatureFilterMethod) {
    updateFilterMethodLabel(elem);
  }

  switch (getStatus(elem)) {
    case "added":
      updateStatus(elem, "preselected", 2, (oldStatus, newStatus) => {
        return oldStatus === "predeselected" || oldStatus === "changed" ? "changed" : newStatus;
      });
      return;
    case "deleted":
      updateStatus(elem, "predeselected", 2, (oldStatus, newStatus) => {
        return oldStatus === "preselected" || oldStatus === "changed" ? "changed" : newStatus;
      });
      return;
    default:
      return;
  }
}

/**
 *
 *
 *
 *
 */
function resetTrackedFacetValue(elem: HTMLInputElement, parents = 2) {
  updateFilterMethodLabel(elem, true);
  updateFacetValueLabel(elem, true);
  if (isDefaultActive(elem)) {
    updateStatus(elem, "selected", parents);
  } else {
    clearStatus(elem);
  }
}

/**
 *
 *
 *
 *
 */
function updateTrackedFacetValue(elem: HTMLInputElement) {
  const status = determineStatus(elem);
  updateFacetValueLabel(elem);
  if (isSingleSelect(elem)) {
    updateStatus(elem, status, 2, undefined, (oldStatus) => {
      return oldStatus === "preselected" ? "predeselected" : undefined;
    });
  } else {
    updateStatus(elem, status, 2);
  }

  return elem;
}

function readTsFeatureFilterMethod(form: HTMLElement, remove = true): TrackingLabels["san_FilterMethod"] | undefined {
  const { tsFeatureFilterMethod } = form.dataset;
  if (remove) {
    delete form.dataset.tsFeatureFilterMethod;
  }
  return tsFeatureFilterMethod as TrackingLabels["san_FilterMethod"] | undefined;
}

export function setChangeLabels(elem: HTMLElement, labels: Partial<TrackingLabels> | undefined) {
  if (labels) {
    elem.dataset.tsChangeLabels = JSON.stringify(labels);
  } else {
    delete elem.dataset.tsChangeLabels;
  }
}

function readChangeLabels(form: HTMLElement, remove = true): Partial<TrackingLabels> | undefined {
  const { tsChangeLabels } = form.dataset;
  if (remove) {
    delete form.dataset.tsChangeLabels;
  }
  return tsChangeLabels ? JSON.parse(tsChangeLabels) : undefined;
}

export function persistTrackingLabels(
  form: HTMLFormElement,
  changedElements: ElementPredicate<HTMLInputElement> = isChanged,
  tsChangeLabels: Partial<TrackingLabels> | undefined = readChangeLabels(form),
  tsFeatureFilterMethod: TrackingLabels["san_FilterMethod"] | undefined = readTsFeatureFilterMethod(form),
  tsFeatureGlobal = !!form.dataset.tsFeatureGlobal,
): number {
  const elem = tsFeatureGlobal ? FILTER_FEATURES : form;

  /*                       */
  const changedFeatures = findFeatures<HTMLInputElement>(elem, changedElements, ".js_tValue").map((input) =>
    persistTrackedStatus(input, tsFeatureFilterMethod || readLabel(input, "san_FilterMethod"), tsChangeLabels),
  ).length;

  /*                                                                 */
  findFeatures<HTMLInputElement>(elem, hasPreStatus, ".js_tValue").forEach((value) => resetTrackedFacetValue(value, 1));

  return changedFeatures;
}

export function persistPreSubmitTrackingLabels(
  form: HTMLFormElement,
  changedElements: ElementPredicate<HTMLInputElement> = isChanged,
  tsFeatureFilterMethod: TrackingLabels["san_FilterMethod"] | undefined = readTsFeatureFilterMethod(form),
  tsFeatureGlobal = !!form.dataset.tsFeatureGlobal,
): number {
  const elem = tsFeatureGlobal ? FILTER_FEATURES : form;

  /*                       */
  return findFeatures<HTMLInputElement>(elem, changedElements, ".js_tValue").map((input) =>
    persistPreSubmitTrackedStatus(input, tsFeatureFilterMethod || readLabel(input, "san_FilterMethod")),
  ).length;
}

export function submitMoveFilterFeature(
  form: HTMLFormElement,
  changedElements: ElementPredicate<HTMLInputElement> = isChanged,
) {
  persistTrackingLabels(form, changedElements);
  const action = (form.dataset.tsFeatureAction as ActionName | undefined) || deriveAction(form);
  const trackingLabels = readLegacyLabels(form);
  submitMoveActionTracking(FILTER_FEATURES, action, trackingLabels);
  return action;
}

/**
 *
 *
 *
 */
function onFilterSubmit(event: Event) {
  if (event.defaultPrevented) {
    return;
  }

  const form = event.target as HTMLFormElement;
  const action = submitMoveFilterFeature(form);

  tracker.trackOnNextPageImpression({
    san_Interaction: `filter ${action}`,
    ts_RemoveLabels: "wk.nav_UnfilteredSelectionRule",
  });
}

export function resetTrackingLabels(form: HTMLFormElement, defaultStatus: FeatureStatus, selector = "") {
  /*                               */
  updateStatus(form, defaultStatus);
  findFeatures(form, isFilterOrFacetFeature).forEach((elem) => updateStatus(elem, defaultStatus));

  /*                                                                                                        */
  findFeatures<HTMLInputElement>(form, isFacetValueFeature, selector).forEach((elem) =>
    resetTrackedFacetValue(elem, 2),
  );
}

/**
 *
 *
 *
 */
function onFilterReset(event: Event) {
  const form = event.target as HTMLFormElement;
  resetTrackingLabels(form, "visible", ".js_tValue");
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
function acceptFeature(id: string): IncludeFeatureCallback {
  const excludedStatus = ["preselected", "predeselected"];
  return (feature) => {
    return (
      feature.name !== "Facet-Value" ||
      (feature.status && excludedStatus.indexOf(feature.status) < 0) ||
      feature.labels?.san_FilterMethod?.includes("automatic") ||
      feature.id === id
    );
  };
}

function trackChangedInput(target: HTMLInputElement, event: ExtendedChangeEvent) {
  /*                                                          */
  /*                                                                                */
  updateFilterMethodLabel(target, readTsFeatureFilterMethod(target, false));
  let doNoSubmitPreselect;

  /*                                                                                              */
  if (target instanceof HTMLInputElement) {
    const counter = parseInt(target.dataset.changeCounter || "0");
    doNoSubmitPreselect = counter > 5;
  }
  /*                                                                              */
  if (target.dataset.doNotSubmitPreselect) {
    doNoSubmitPreselect = true;
    delete target.dataset.doNotSubmitPreselect;
  }

  /*                                                      */
  const elem = dereference(target);
  if (elem.dataset.tsFeatureName) {
    updateTrackedFacetValue(elem);
    if (!event.triggerSubmit && !doNoSubmitPreselect) {
      const action = deriveAction(elem);
      const trackingLabels = (elem.form && readLegacyLabels(elem.form)) || {};
      submitActionTracking(FILTER_FEATURES, action, trackingLabels, acceptFeature(elem.id));
    }
  }
}

function trackChangedForm(form: HTMLFormElement, event: ExtendedChangeEvent) {
  const inputs = findFeatures<HTMLInputElement>(form, or(isChanged, hasPreStatus), ".js_tValue").map(
    updateTrackedFacetValue,
  );

  if (!event.triggerSubmit && inputs.length) {
    const action = deriveCommonAction(inputs);
    const trackingLabels = readLegacyLabels(form) || {};
    submitActionTracking(FILTER_FEATURES, action, trackingLabels);
  }
}

/**
 *
 *
 *
 */
function onFacetValueChange(event: ExtendedChangeEvent) {
  const target = event.target;
  if (target instanceof HTMLInputElement) {
    trackChangedInput(target, event);
  }
  if (target instanceof HTMLFormElement) {
    trackChangedForm(target, event);
  }
}

function onSubmitWithFilterMethod(event: Event) {
  const elem = event.target as HTMLButtonElement | HTMLInputElement;
  const tsFeatureFilterMethod = readTsFeatureFilterMethod(elem, false);
  if (elem.form && tsFeatureFilterMethod) {
    elem.form.dataset.tsFeatureFilterMethod = tsFeatureFilterMethod;
  }
}

/**
 *
 *
 *
 */
function trackOnChange(elem: HTMLElement) {
  elem.addEventListener("change", onFacetValueChange, { passive: true });
  elem.addEventListener("reset", onFilterReset, { passive: true });
}

function trackFilterMethod(elem: HTMLButtonElement) {
  elem.addEventListener("click", onSubmitWithFilterMethod, { capture: true, passive: true });
}

/**
 *
 */
function onFilterSectionLoaded() {
  forEachElement(".js_trackOnChange", trackOnChange);
  forEachElement(".js_trackFilterMethod", trackFilterMethod);
}

/**
 *
 */
function addFeatureTracking() {
  addFeaturesToPageImpressionTracking(FILTER_FEATURES);
}

export function addSanErrorToPageImpression(sanError: TrackingLabels["san_error"]) {
  tracker.addToPageImpression({
    san_error: sanError,
  });
}

/**
 *
 *
 *
 */
export function submitAllAsAction(
  name: ActionName,
  additionalTrackingLabels: Partial<TrackingLabels> | EventMergeId = {},
) {
  submitActionTracking(FILTER_FEATURES, name, additionalTrackingLabels);
}

/**
 *
 *
 *
 *
 */
export function updateFeatureStatus(
  statusToUpdate: FeatureStatus,
  updatedStatus: FeatureStatus,
  selectors: string[] = ["#find_filterSection"],
) {
  selectors.forEach((selector) => {
    const filterContainers = document.querySelectorAll<HTMLElement>(selector);
    if (filterContainers) {
      filterContainers.forEach((filterContainer) =>
        findFeatures(filterContainer, isFilterOrFacetFeature).forEach((elem) =>
          updateStatus(elem, updatedStatus, undefined, (oldStatus, newStatus) => {
            return oldStatus === statusToUpdate ? newStatus : oldStatus;
          }),
        ),
      );
    }
  });
}

/**
 *
 */
export function isFilterOrFacetFeature(elem: HTMLElement) {
  return elem.dataset.tsFeatureName === "Filter" || elem.dataset.tsFeatureName === "Facet";
}

/**
 *
 */
function isFacetValueFeature(elem: HTMLElement) {
  return elem.dataset.tsFeatureName === "Facet-Value";
}

function handleFeatureStatusOnBreakPointChange(event: MediaQueryListEvent) {
  if (event.matches) {
    updateFeatureStatus("loaded", "visible");
  }
}

export default function registerFilterTracking() {
  eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
  eventQBus.on("heureka.filterSection.loaded", addFeatureTracking);
  document.addEventListener("filterSubmit", onFilterSubmit);

  onBreakpointChange("large", handleFeatureStatusOnBreakPointChange);
  onBreakpointChange("extraLarge", handleFeatureStatusOnBreakPointChange);
}
