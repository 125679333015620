import { forEachElement } from "../util/Utils";

/**
 *
 */
export class UpdateReferencedAction implements EventListenerObject {
  /*                  */

  static on(elem: HTMLElement, listener = new UpdateReferencedAction()) {
    elem.addEventListener("input", listener, {
      passive: true,
    });
  }

  /*               */

  /*                 */

  handleEvent(event: Event) {
    const { target } = event;
    if (target instanceof HTMLInputElement) {
      UpdateReferencedAction.updateAllValues(target);
    }
  }

  static updateAllValues(target: HTMLInputElement) {
    const { name, value, checked } = target;
    forEachElement<HTMLInputElement>(`input[name="${name}"][value="${value}"]`, (input) => {
      input.checked = checked;
    });
  }
}
