import type { FeatureTracking } from "./FeatureTracking";
import type { FeatureTrackingReference } from "./FeatureTrackingReference";

export function addTrackingAttributes(node: HTMLElement, featureTracking: FeatureTracking | undefined) {
  /*                                          */
  if (featureTracking) {
    const featureLabels = featureTracking.labels ? JSON.stringify(featureTracking.labels) : undefined;
    const position = featureTracking.position;
    const attributes = {
      tsFeatureLabels: featureLabels,
      tsFeatureFilterMethod: featureTracking.method,
      tsFeatureName: featureTracking.name,
      tsFeatureParentId: featureTracking.parentId,
      tsFeaturePosition: position && Number(position) > 0 ? position.toString() : undefined,
      tsFeatureStatus: featureTracking.status,
    };

    Object.entries(attributes).forEach(([key, value]) => {
      if (value !== undefined) {
        node.dataset[key] = value;
      }
    });
  }

  return {
    update() {},
    destroy() {},
  };
}

export function addFeatureTrackingReference(
  node: HTMLElement,
  featureTrackingReference: Partial<FeatureTrackingReference> | undefined,
) {
  /*                                          */
  if (featureTrackingReference?.ref) {
    const featureLabels = featureTrackingReference.labels ? JSON.stringify(featureTrackingReference.labels) : undefined;

    const attributes = {
      tsFeatureLabels: featureLabels,
      tsFeatureRef: featureTrackingReference.ref,
    };

    Object.entries(attributes).forEach(([key, value]) => {
      if (value !== undefined) {
        node.dataset[key] = value;
      }
    });
  }

  return {
    update() {},
    destroy() {},
  };
}
