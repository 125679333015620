<script lang="ts">
  import type { FeatureTracking } from "../FeatureTracking";
  import type { FacetValue } from "../FacetValue";
  import { addTrackingAttributes } from "../Tracking";
  import inputDefault from "../InputDefaultAction.ts";
  import SwitchInput from "../../input/SwitchInput.svelte";
  import Label from "../../input/Label.svelte";

  interface Props {
    elementId: string;
    facetName: string | undefined;
    type?: "radio" | "checkbox";
    selectedValues?: string | string[];
    activeValue: FacetValue;
    featureTracking?: FeatureTracking;
  }

  let {
    elementId,
    facetName,
    type = "checkbox",
    selectedValues = $bindable(),
    activeValue,
    featureTracking = undefined,
  }: Props = $props();

  /*                     */
  if (featureTracking) {
    if (!activeValue.selected) {
      delete featureTracking.status;
    }
  }

  function onchange(event: Event & { currentTarget: HTMLInputElement }) {
    selectedValues = event.currentTarget.checked
      ? [...(selectedValues || []), activeValue.value]
      : selectedValues?.filter((value) => value !== activeValue.value);
  }
</script>

{#if type === "checkbox"}
  <SwitchInput fitContent={false} labelPlacement="left">
    {#snippet input()}
      <input
        id={elementId}
        class="js_tValue"
        name={facetName}
        type="checkbox"
        value={activeValue.value}
        checked={activeValue.selected}
        data-ts-feature-filter-method="switch"
        use:addTrackingAttributes={featureTracking}
        use:inputDefault
        {onchange}
      />
    {/snippet}
    <Label title={activeValue.displayName} itemCount={activeValue.itemCount} bold />
  </SwitchInput>
{:else}
  <SwitchInput fitContent={false} labelPlacement="left">
    {#snippet input()}
      <input
        id={elementId}
        class="js_tValue"
        name={facetName}
        type="radio"
        value={activeValue.value}
        checked={activeValue.selected}
        bind:group={selectedValues}
        data-ts-feature-filter-method="switch"
        use:addTrackingAttributes={featureTracking}
        use:inputDefault
      />
    {/snippet}
    <Label title={activeValue.displayName} itemCount={activeValue.itemCount} bold />
  </SwitchInput>
{/if}
