const FILTER_HEADER_BUTTONS_CLASS = "heureka_filterHeaderButtons";
const FILTER_HEADER_BUTTONS_SELECTOR = `.${FILTER_HEADER_BUTTONS_CLASS}`;
export function filterHeaderButtons(element: ParentNode | null) {
  return element?.querySelector<HTMLElement>(FILTER_HEADER_BUTTONS_SELECTOR) || null;
}
export function createEmptyFilterHeaderButtons() {
  const container = document.createElement("div");
  container.classList.add(FILTER_HEADER_BUTTONS_CLASS);
  return container;
}
