import { element, forEachElement, mapElements } from "./Utils";

interface HeurekaElement<T, E extends HTMLElement> {
  new (elem: E): T;
}

type DomElement<N extends keyof HTMLElementTagNameMap> = {
  prototype: HTMLElementTagNameMap[N];
  new (): HTMLElementTagNameMap[N];
};

function lookUp<N extends keyof HTMLElementTagNameMap>(elem: N): DomElement<N> {
  switch (elem) {
    case "a":
      return HTMLAnchorElement as never;
    case "button":
      return HTMLButtonElement as never;
    case "form":
      return HTMLFormElement as never;
    default:
      return HTMLElement as never;
  }
}

export type UnwrapMethod = (target: HTMLElement) => void;

export class HeurekaElementFactory<T, N extends keyof HTMLElementTagNameMap> {
  constructor(
    readonly selector: string,
    private readonly creatable: (elem: HTMLElementTagNameMap[N]) => boolean,
    private readonly type: HeurekaElement<T, HTMLElementTagNameMap[N]>,
    private readonly elementType: DomElement<N>,
    private readonly unwrapMethod: UnwrapMethod | undefined = undefined,
  ) {}

  /*                  */

  public static byElement<T, N extends keyof HTMLElementTagNameMap>(
    element: N,
    cssClass: string,
    type: HeurekaElement<T, HTMLElementTagNameMap[N]>,
  ) {
    return new HeurekaElementFactory(
      `${element}.${cssClass}`,
      (elem) => elem.classList.contains(cssClass),
      type,
      lookUp(element),
    );
  }

  public static byId<T>(elementId: string, type: HeurekaElement<T, HTMLElement>) {
    return new HeurekaElementFactory(`#${elementId}`, (elem) => elem.id === elementId, type, HTMLElement);
  }

  public static byClass<T>(cssClass: string, type: HeurekaElement<T, HTMLElement>) {
    return new HeurekaElementFactory(
      `.${cssClass}`,
      (elem) => elem.classList.contains(cssClass),
      type,
      HTMLElement,
      (elem) => elem.classList.remove(cssClass),
    );
  }

  /*                       */

  create(elem: HTMLElementTagNameMap[N]) {
    return new this.type(elem);
  }

  declare(elem?: HTMLElement | EventTarget | null): T | undefined {
    try {
      if (elem instanceof this.elementType) {
        return this.creatable(elem) ? this.create(elem) : undefined;
      }
    } catch (e) {
      console.warn("Mystery Failure", e);
    }
  }

  release(elem: HTMLElement) {
    if (this.unwrapMethod) {
      this.unwrapMethod(elem);
    }
  }

  pick(selector: string = this.selector, root?: ParentNode | null) {
    return this.declare(element(selector, root));
  }

  pickAll(selector: string = this.selector, root?: ParentNode | null): T[] {
    return mapElements(selector, this.create.bind(this), root);
  }

  async picking(selector: string = this.selector, root?: ParentNode | null): Promise<T> {
    const elem = this.pick(selector, root);
    if (elem === undefined) {
      throw new Error(`Unable to locate element with ${this.selector}`);
    }
    return elem;
  }

  byId(id: string, root: NonElementParentNode = document) {
    return this.declare(root.getElementById(id));
  }

  closest(elem: HTMLElement | EventTarget | null) {
    if (elem instanceof HTMLElement) {
      const input = elem.closest<HTMLElementTagNameMap[N]>(this.selector);
      return input ? this.create(input) : undefined;
    }
  }

  forEach(callback: (input: T, index: number) => void, rootElement?: ParentNode) {
    forEachElement<HTMLElementTagNameMap[N]>(
      this.selector,
      (elem, index) => callback(this.create(elem), index),
      rootElement,
    );
  }

  all(root?: ParentNode | null): T[] {
    return mapElements(this.selector, this.create.bind(this), root);
  }
}
