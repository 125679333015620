import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import type { FacetVisibilityAware } from "./FacetVisibilityAware";
import { updateWhatIfTracking } from "../tracking/WhatIfTracking";
import { Facet } from "./Facet";
import { eventQBus } from "../types/EventQBus";
import type { FacetExperiment } from "./FacetExperiment";

const SHADOW_FACET_CLASS = "find_shadow_facet";

interface ExperimentTrackingLabels {
  [onex: string]: Array<string>;
}

export class ShadowFacet implements FacetVisibilityAware, FacetExperiment {
  static readonly factory = HeurekaElementFactory.byElement("fieldset", SHADOW_FACET_CLASS, ShadowFacet);

  constructor(readonly facet: HTMLFieldSetElement) {}

  /*                  */

  static facetId(id: string, root?: NonElementParentNode): ShadowFacet | undefined {
    return ShadowFacet.factory.byId(`shadow_facet_${id}`, root);
  }

  static facetName(facetName: string, root?: ParentNode): ShadowFacet | undefined {
    return ShadowFacet.factory.pick(`fieldset.find_shadow_facet[data-facet-name="${facetName}"]`, root);
  }

  /*               */
  static prepareAll(fragment: DocumentFragment) {
    ShadowFacet.factory.forEach((facet: ShadowFacet) => facet.prepare(), fragment);
  }

  private prepare() {
    const oldFacet = ShadowFacet.facetId(this.id);
    if (oldFacet) {
      this.updateVisibility(oldFacet.hidden);
    }
  }

  public static register() {
    eventQBus.on("heureka.filterSection.loaded", ShadowFacet.tracking);
  }

  /*                       */

  get onexId(): string | undefined {
    return this.facet.dataset.onexid;
  }

  get onexGroup(): string | undefined {
    return this.facet.dataset.onexgroup;
  }

  get id(): string {
    return this.facet.id.substring("shadow_facet_".length);
  }

  get name(): string | undefined {
    return this.facet.dataset?.facetName;
  }

  private get facetVisibility() {
    return this.facet.dataset.facetVisibility;
  }

  get mayHide() {
    return this.facetVisibility !== "visible";
  }

  get mayShow() {
    return this.facetVisibility !== "hidden";
  }

  get hidden(): boolean {
    return this.facet.hidden;
  }

  set hidden(hidden) {
    this.facet.hidden = hidden;
  }

  updateVisibility(shouldHide: boolean) {
    this.hidden = shouldHide ? this.mayHide : !this.mayShow;
  }

  /*       */

  private static tracking() {
    const actualFacetExperiments = ShadowFacet.experimentTrackingLabels(Facet.factory.all());
    const shadowFacetExperiments = ShadowFacet.experimentTrackingLabels(ShadowFacet.factory.all());

    Object.keys(shadowFacetExperiments).forEach((onex: string) => {
      const actualFacetLabels = actualFacetExperiments[onex];
      const shadowFacetLabels = shadowFacetExperiments[onex];

      if (actualFacetLabels && actualFacetLabels.length > 0 && shadowFacetLabels && shadowFacetLabels.length > 0) {
        updateWhatIfTracking(shadowFacetLabels.concat(actualFacetLabels).join("|"));
      }
    });
  }

  private static experimentTrackingLabels(facets: FacetExperiment[]): ExperimentTrackingLabels {
    return facets
      .filter((facet) => facet.onexId)
      .reduce((mapped: ExperimentTrackingLabels, facet: FacetExperiment) => {
        const key = `${facet.onexId}`.toLowerCase();
        const facetName = facet.name;
        return {
          ...mapped,
          [key]: (mapped[key] || []).concat(`${facet.onexId}_${facet.onexGroup}_${facetName}_${!facet.hidden}`),
        };
      }, {});
  }
}
