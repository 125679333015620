import { eventQBus } from "../types/EventQBus";
import { clear, element, forEachElement } from "../util/Utils";
import { SortingForm } from "../sorting/SortingForm";
import { FacetValueTags } from "../filter/FacetValueTags";
import { isActive } from "../toggle/Toggle";
import type { Appendable } from "../util/Appendable";
import { Facet } from "../filter/Facet";
import { UpdateReferencedAction } from "../filter/UpdateReferencedAction";
import { SubmitOnChangeListener } from "../filter/SubmitOnChange";
import type { FacetValue } from "../filter/FacetValue";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { setChangeLabels } from "../filter/FilterTracking";
import type { DresonRuleResolvedEvent } from "../multifiltering/FilterTypes";
import FilterSwitchBanner from "../filterBanner/FilterSwitchBanner";

const CLASS_REFINEMENT_BAR = "find_refinementBar";
const SELECTOR_REFINEMENT_BAR = `.${CLASS_REFINEMENT_BAR}`;

const ID_SORTING_FORM_ANCHOR = "heureka_desktopSorting";
const SELECTOR_SORTING_FORM_ANCHOR = `#${ID_SORTING_FORM_ANCHOR}`;
const ID_FACET_VALUE_TAGS_ANCHOR = "find_facetValueTags";
const SELECTOR_FACET_VALUE_TAGS_ANCHOR = `#${ID_FACET_VALUE_TAGS_ANCHOR}`;
const ID_BANNER_ANCHOR = "heureka_filterBanner";
const SELECTOR_BANNER_ANCHOR = `#${ID_BANNER_ANCHOR}`;

const CHANGE_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: undefined,
  san_FilterMethod: "x_single_top",
};

export class RefinementBar {
  /*               */
  protected constructor(
    private readonly sortingFormAnchor: HTMLElement | null,
    private readonly facetValueTagsAnchor: HTMLElement | null,
    private readonly filterBannerAnchor: HTMLElement | null,
  ) {}

  static #create(elem: HTMLElement) {
    /*                                               */
    if (!document.getElementById(ID_BANNER_ANCHOR) && document.querySelector(SELECTOR_REFINEMENT_BAR)) {
      const ottoSwitchBannerAnchor = document.createElement("div");
      ottoSwitchBannerAnchor.id = ID_BANNER_ANCHOR;
      document.querySelector(SELECTOR_REFINEMENT_BAR)?.append(ottoSwitchBannerAnchor);
    }
    return new RefinementBar(
      element(SELECTOR_SORTING_FORM_ANCHOR, elem),
      element(SELECTOR_FACET_VALUE_TAGS_ANCHOR, elem),
      element(SELECTOR_BANNER_ANCHOR, elem),
    );
  }

  /*                  */

  static forEach(callback: (input: RefinementBar, index: number) => void, rootElement?: ParentNode) {
    forEachElement<HTMLElement>(
      SELECTOR_REFINEMENT_BAR,
      (elem, index) => callback(RefinementBar.#create(elem), index),
      rootElement,
    );
  }

  /*               */

  static lockAll() {
    RefinementBar.forEach((refinementBar) => refinementBar.lock());
  }

  static unlockAll() {
    RefinementBar.forEach((refinementBar) => refinementBar.unlock());
  }

  static register() {
    eventQBus.on("ftfind.tilelist.loaded", RefinementBar.initAll);
    eventQBus.on("heureka.filterSection.loaded", RefinementBar.initAll);
    eventQBus.on("heureka.filterPersonalizationBanner.loaded", RefinementBar.initAll);

    /*      */
    eventQBus.on("ftfind.dresonRule.resolve", RefinementBar.lockAll);

    /*        */
    eventQBus.on("ftfind.dresonRule.resolved", RefinementBar.onDresonRuleResolved);
    eventQBus.on("heureka.refinementBar.loaded", RefinementBar.unlockAll);
  }

  static initAll(event?: unknown, rootElement?: ParentNode) {
    RefinementBar.forEach((refinementBar) => refinementBar.init(), rootElement);
    eventQBus.emit("heureka.refinementBar.loaded");
  }

  static onDresonRuleResolved(data: DresonRuleResolvedEvent) {
    /*                       */
    if (data.count == 0) {
      RefinementBar.unlockAll();
    }
  }

  init() {
    const form: SortingForm | undefined = SortingForm.template();
    if (form) {
      this.sortingForm = form.clone();
    }

    this.clearRefinementForm();
    this.clearFilterBanner();

    const facetValueTags = FacetValueTags.template()?.clone();
    if (isActive("HEUREKA_1367_REFACTOR_FACET_VALUE_TAGS") && facetValueTags) {
      /*                           */
      /*                                                                                                      */
      /*                                               */
      const insertionIndex = facetValueTags.findIndexOf(
        (facetValueTag) => facetValueTag.isCategoryPathFacetValueTagForm,
      );
      /*                                                                                             */
      if (insertionIndex >= 0) {
        const categoryPathFacet = Facet.facetName("kategorien");
        let categoryPathSelectedFacetValues: FacetValue[] = [];
        if (categoryPathFacet) {
          categoryPathSelectedFacetValues = categoryPathFacet.selectedFacetValues || [];
          if (history.state?.categories && history.state.categories.length > 0) {
            /*                                                                             */
            categoryPathSelectedFacetValues = categoryPathSelectedFacetValues.filter((facetValue) =>
              history.state.categories.includes(facetValue.value),
            );
            /*                                                                            */
            const extraFacetValues = categoryPathFacet.unSelectedFacetValues.filter(
              (facetValue) => facetValue && history.state.categories.includes(facetValue.value),
            );
            categoryPathSelectedFacetValues.push(...extraFacetValues);
          }
        }

        /*                                                                               */
        const categoryPathFacetValueTags = this.createFacetValueTagsFrom(categoryPathSelectedFacetValues);
        facetValueTags.appendFacetValues(categoryPathFacetValueTags, insertionIndex);
        facetValueTags.removeFacetValueTagsOf((facetValueTag) => facetValueTag.isCategoryPathFacetValueTagForm);
      }
    }

    this.addToRefinementForm(facetValueTags);
    this.addToFilterBanner(FilterSwitchBanner.fromTemplate()?.clone());
  }

  private createFacetValueTagsFrom(facetValues: FacetValue[]): FacetValue[] {
    return (
      facetValues?.map((facetValue) => {
        const facetValueCopy = facetValue.clone().clearStatus().transformToFacetValueTag().withRef(facetValue.id);
        if (facetValueCopy.input) {
          UpdateReferencedAction.on(facetValueCopy.input);
          if (facetValue.input?.form?.id) {
            SubmitOnChangeListener.onForForm(facetValueCopy.input, facetValue.input.form.id, () =>
              this.setFacetValueTagTrackingLabelsOn(facetValue.input?.form),
            );
          }
        }
        return facetValueCopy;
      }) || []
    );
  }

  private setFacetValueTagTrackingLabelsOn(filterForm: HTMLFormElement | null | undefined) {
    if (filterForm) {
      setChangeLabels(filterForm, CHANGE_TRACKING_LABELS);
    }
  }

  /*                       */

  public lock() {
    this.sortingFormAnchor?.classList.add("find_refinementBar--loading");
    this.facetValueTagsAnchor?.classList.add("find_refinementBar--loading");
    this.filterBannerAnchor?.classList.add("find_refinementBar--loading");
    return this;
  }

  public unlock() {
    this.sortingFormAnchor?.classList.remove("find_refinementBar--loading");
    this.facetValueTagsAnchor?.classList.remove("find_refinementBar--loading");
    this.filterBannerAnchor?.classList.remove("find_refinementBar--loading");
    return this;
  }

  set sortingForm(form: SortingForm) {
    const anchor = this.sortingFormAnchor;
    if (anchor) {
      clear(anchor);
      anchor.appendChild(form.form);
    }
  }

  private clearRefinementForm() {
    const anchor = this.facetValueTagsAnchor;
    if (anchor) {
      clear(anchor);
    }
  }

  private addToRefinementForm(form: Appendable | undefined) {
    const anchor = this.facetValueTagsAnchor;
    if (anchor && form) {
      form.appendTo(anchor);
    }
  }

  private addToFilterBanner(elem: Appendable | undefined) {
    const anchor = this.filterBannerAnchor;
    if (anchor && elem) {
      elem.appendTo(anchor);
    }
  }

  private clearFilterBanner() {
    const anchor = this.filterBannerAnchor;
    if (anchor) {
      clear(anchor);
    }
  }
}
