import type { SliderTypes } from "./SliderTypes.ts";

export class SliderHost {
  minPos: number;

  maxPos: number;

  minVal: number;

  maxVal: number;

  logarithmic: boolean;

  minLogVal: number;

  maxLogVal: number;

  logScale: number;

  linScale: number;

  constructor(settings: SliderTypes) {
    /*                                                                      */
    this.minPos = 0;
    this.maxPos = Number(settings.maxPos) || 100;
    this.minVal = Number(settings.minVal);
    this.maxVal = Number(settings.maxVal);

    this.logarithmic = settings.logarithmic || false;
    /*                                                                   */
    /*                                  */
    this.minLogVal = this.minVal === 0 ? -1 : Math.log(this.minVal || 0);
    this.maxLogVal = Math.log(this.maxVal);

    /*                          */
    this.logScale = (this.maxLogVal - this.minLogVal) / this.maxPos;
    this.linScale = (this.maxVal - this.minVal) / this.maxPos;
  }

  calcValue(position: number): number {
    return this.logarithmic ? this.calcLogValue(position) : this.calcLinValue(position);
  }

  calcPosition(value: number): number {
    return this.logarithmic ? this.calcLogPosition(value) : this.calcLinPosition(value);
  }

  /**
 *
 */
  private calcLinValue(position: number): number {
    return position * this.linScale + this.minVal;
  }

  /**
 *
 */
  private calcLogValue(position: number): number {
    return Math.exp(position * this.logScale + this.minLogVal);
  }

  /**
 *
 */
  private calcLinPosition(value: number): number {
    return (value - this.minVal) / this.linScale;
  }

  /**
 *
 */
  private calcLogPosition(value: number): number {
    return value === 0 ? 0 : (Math.log(value) - this.minLogVal) / this.logScale;
  }
}
