import { FacetValueTags } from "./FacetValueTags";
import { FilterAccordion } from "./FilterAccordion";
import { FacetGroupSelect } from "./FacetGroupSelect";
import { FilterAccordions } from "./FilterAccordions";
import { FilterTitles } from "./FilterTitles";
import { Filter } from "./Filter";
import { ShadowFacet } from "./ShadowFacet";

export function registerFilterStuff() {
  FilterAccordion.register();
  FacetGroupSelect.register();
  FacetValueTags.register();
  FilterAccordions.register();
  FilterTitles.register();
  Filter.register();
  ShadowFacet.register();
}
