import { eventQBus } from "../../types/EventQBus";
import { fireFilterSubmit } from "../FilterFormActions";
import type { TrackingLabels } from "../../tracking/TrackingLabels";
import DelayedAction from "../../util/DelayedAction";
import { FacetValue } from "../FacetValue";
import { element, isDesktopDevice } from "../../util/Utils";
import { HeurekaElementFactory } from "../../util/HeurekaElementFactory";
import tracker from "../../tracking/Tracker";

const CUSTOM_RANGE_CLASS = "hc_customRange";
const CUSTOM_RANGE_SELECTOR = `.${CUSTOM_RANGE_CLASS}`;
const CUSTOM_RANGE_INPUT_CLASS = `${CUSTOM_RANGE_CLASS}__input`;
const CUSTOM_RANGE_INPUT_SELECTOR = `.${CUSTOM_RANGE_INPUT_CLASS}`;
const MINMAX_FIELD_SELECTOR = "input[type=number]";

export class CustomRangeInput {
  static readonly factory = HeurekaElementFactory.byElement("input", CUSTOM_RANGE_INPUT_CLASS, CustomRangeInput);

  /*               */
  constructor(
    readonly elem: HTMLInputElement,
    readonly form: HTMLFormElement | null = elem.form,
    readonly customRange: HTMLElement | null = elem.closest(CUSTOM_RANGE_SELECTOR),
    readonly sliderField: HTMLInputElement | null = elem.querySelector<HTMLInputElement>(CUSTOM_RANGE_INPUT_SELECTOR) ||
      null,
    readonly minField: HTMLInputElement | null = customRange?.querySelectorAll<HTMLInputElement>(
      MINMAX_FIELD_SELECTOR,
    )[0] || null,
    readonly maxField: HTMLInputElement | null = customRange?.querySelectorAll<HTMLInputElement>(
      MINMAX_FIELD_SELECTOR,
    )[1] || null,
  ) {}

  /*                  */
  static fromId(sliderId: string, root?: ParentNode) {
    return CustomRangeInput.factory.declare(element(`#${sliderId}`, root)?.closest(CUSTOM_RANGE_SELECTOR));
  }

  static init(elem: HTMLElement) {
    return CustomRangeInput.factory.pick(undefined, elem)?.init();
  }

  /*               */

  static register() {
    eventQBus.on("heureka.sliders.initialised", CustomRangeInput.#initAll);
  }

  static #initAll() {
    CustomRangeInput.factory.all().forEach((customRangeInput) => customRangeInput.init());
  }

  private init() {
    if (this.elem.dataset.heurekaSliderInitialized) {
      /*                   */
      return;
    }
    this.elem.dataset.heurekaSliderInitialized = "true";
    if (this.customRange) {
      this.initializeAutoSubmit();

      this.initializeInputField(this.minField, 0);
      this.initializeInputField(this.maxField, 1);
    }
    return this;
  }

  private initializeAutoSubmit() {
    if (this.form && this.customRange) {
      const delayedAction = DelayedAction.create(this.form, () => this.fireSliderAutoSubmit("adopt_delayed"));

      const doCancel = delayedAction.cancel.bind(delayedAction);
      const doDelay = delayedAction.delay.bind(delayedAction, {});
      const doDelayNoWait = delayedAction.delay.bind(delayedAction, {
        delayInMillis: 0,
        actionToPerform: () => this.fireSliderAutoSubmit(), /*                          */
      });
      const doThrottle = delayedAction.throttle.bind(delayedAction, {
        throttleInMillis: 50,
      });
      const options = { passive: true };

      /*                                                                                                  */
      this.customRange.addEventListener("pointerdown", doCancel, options);
      this.customRange.addEventListener("pointerup", doDelay, options);

      this.customRange.addEventListener("focusin", doCancel, options);
      this.customRange.addEventListener("focusout", doDelayNoWait, options);

      /*                                                                                                    */
      /*                                                                                                    */
      /*                                               */
      if (isDesktopDevice()) {
        this.form.addEventListener(
          "mousedown",
          (event) => {
            if (FacetValue.isFacetValueUserInteraction(event)) doThrottle();
          },
          options,
        );
      }

      this.form.addEventListener(
        "click",
        (event) => {
          if (event.target instanceof HTMLInputElement && event.target.type !== "range") doCancel();
        },
        options,
      );

      this.form.addEventListener("filterSubmit", doCancel, options);
      this.form.addEventListener("reset", doCancel, options);
    }
  }

  private initializeInputField(inputField: HTMLInputElement | undefined | null, limitIndex: number) {
    if (this.customRange && inputField) {
      const limitKey = limitIndex === 0 ? "heurekaSliderInitialMinValue" : "heurekaSliderInitialMaxValue";
      const limitValue = this.customRange.dataset[limitKey];
      const limitType = limitIndex === 0 ? "min" : "max";
      const { facetName } = this.customRange.dataset;
      if (limitValue) {
        inputField.value = limitValue;
        inputField.dispatchEvent(new Event("change"));
      }
      if (facetName) {
        inputField.addEventListener("focusin", this.trackFocus(`click_${limitType}`, facetName), {
          passive: true,
        });
        inputField.addEventListener("input", this.trackDelete(`delete_${limitType}`, facetName), {
          passive: true,
        });
      }
    }
  }

  private fireSliderAutoSubmit(filterMethod?: string) {
    /*                                                         */
    if (this.form && this.form.closest(".heureka_filterTitle__values")) {
      if (filterMethod) {
        this.form.dataset.tsFeatureFilterMethod = filterMethod;
      }
      return fireFilterSubmit(this.form);
    }
    return false;
  }

  /*                       */

  get minLabel(): string {
    return this.customRange?.dataset.sliderMinLabel || "";
  }

  get maxLabel(): string {
    return this.customRange?.dataset.sliderMaxLabel || "";
  }

  get minValue(): string {
    return this.customRange?.dataset.sliderMinVal || "";
  }

  get maxValue(): string {
    return this.customRange?.dataset.sliderMaxVal || "";
  }

  get facetName(): string {
    return this.customRange?.dataset.facetName || "";
  }

  get initialMinValue(): string {
    return this.customRange?.dataset.heurekaSliderInitialMinValue || "";
  }

  get initialMaxValue(): string {
    return this.customRange?.dataset.heurekaSliderInitialMaxValue || "";
  }

  get availablePreselects(): string {
    return this.customRange?.dataset.heurekaSliderAvailablePreselects || "";
  }

  get logarithmic(): string {
    return this.customRange?.dataset.sliderLogarithmic || "";
  }

  get delayedActionMillis(): string {
    return this.customRange?.dataset.delayedActionMillis || "";
  }

  get showDefaultPlaceholders(): string {
    return this.customRange?.dataset.sliderShowDefaultPlaceholders || "";
  }

  get focusFloatsBothLabels(): string {
    return this.customRange?.dataset.sliderFocusFloatsBothLabels || "";
  }

  get hideSlider(): string {
    return this.customRange?.dataset.hideSlider || "";
  }

  get id(): string {
    return this.customRange?.id || "";
  }

  /*                               */
  /*                     */
  /*                                                                                                 */
  /*  */
  /*           */
  /**/

  set autoSubmitAllowed(value: boolean) {
    if (this.form) this.form.dataset["autoSubmit"] = value ? "allowed" : "forbidden";
  }

  get autoSubmitAllowed(): boolean {
    return this.form?.dataset["autoSubmit"] !== "forbidden";
  }

  /*       */

  private trackFocus(
    sanFacetActivity: TrackingLabels["san_FacetActivity"],
    sanFacetActivityType: TrackingLabels["san_FacetActivityType"],
  ): EventListener {
    return () => {
      tracker.submitEvent({
        san_FacetActivity: sanFacetActivity,
        san_FacetActivityType: sanFacetActivityType,
      });
    };
  }

  private trackDelete(
    sanFacetActivity: TrackingLabels["san_FacetActivity"],
    sanFacetActivityType: TrackingLabels["san_FacetActivityType"],
  ): EventListener {
    return (event) => {
      const target = event.target;
      if (target instanceof HTMLInputElement && !target.value) {
        tracker.submitEvent({
          san_FacetActivity: sanFacetActivity,
          san_FacetActivityType: sanFacetActivityType,
        });
      }
    };
  }
}
