import { HeurekaElementFactory } from "../util/HeurekaElementFactory";

const TS_BCT_CLASS = "ts-bct";

/**
 *
 */
export class TsBct {
  static readonly factory = HeurekaElementFactory.byClass(TS_BCT_CLASS, TsBct);

  /*               */
  constructor(readonly elem: HTMLElement) {}

  /*                  */

  static instance(root?: ParentNode) {
    return TsBct.factory.pick(undefined, root);
  }

  static sfid(root?: ParentNode) {
    return this.instance(root)?.sfid;
  }

  /*               */

  /*                       */

  get sfid(): string | undefined {
    return this.elem.dataset["ts_sfid"];
  }
}
