import { copyLabels, dereference } from "../tracking/FeatureTracking";
import { allElements, forEachElement, mapElements, toArray } from "../util/Utils";
import { eventQBus } from "../types/EventQBus";
import { eventLoader } from "@otto-ec/global-resources/event-loader";
import SvelteSliderOld from "@otto-ec/heureka-components/slider";
import SvelteSwitch from "@otto-ec/heureka-components/facet-value/switch";
import CompositeRange from "@otto-ec/heureka-components/facet-value/custom-range";
import { Slider } from "./slider/Slider";
import { isMobile } from "../util/Breakpoint";
import { CustomRangeInput } from "./slider/CustomRangeInput";
import { isActive } from "../toggle/Toggle";
import { mount } from "svelte";

export const CLASS_JS_FORCE_FORCE_SUBMIT = "js_forceFormSubmit";

const JS_CLEAR_FORM = ".js_clearForm";
const JS_CLEAR_INPUT = ".js_clearInput";
const JS_CLEAR_ALL_INPUTS = ".js_clearAllInputs";

export type FilterSubmitEvent = CustomEvent<{
  submitter: HTMLFormElement | HTMLInputElement | HTMLSelectElement;
}>;

function createFilterSubmitEvent(submitter: HTMLFormElement | HTMLInputElement | HTMLSelectElement): FilterSubmitEvent {
  return new CustomEvent("filterSubmit", {
    bubbles: true,
    cancelable: true,
    detail: {
      submitter: submitter,
    },
  });
}

export function isForceSubmit(form: HTMLFormElement) {
  return form.classList.contains(CLASS_JS_FORCE_FORCE_SUBMIT);
}

/**
 *
 *
 *
 *
 */
export function fireFilterSubmit(target: HTMLFormElement | HTMLInputElement | HTMLSelectElement): boolean {
  const form = target instanceof HTMLFormElement ? target : target.form;
  if (!form) {
    /*                                                                              */
    return false;
  }
  const filterEvent = createFilterSubmitEvent(target);

  const preventSubmission = !isForceSubmit(form) && !hasChanges(form);

  if (preventSubmission) {
    filterEvent.preventDefault();
  }

  form.dispatchEvent(filterEvent);

  return !preventSubmission;
}

/**
 *
 *
 *
 *
 */
export function isChanged(elem?: Element | HTMLInputElement) {
  if (elem && elem.classList.contains("js_tValue") && elem.getAttribute("type")) {
    /*                                                                                                                                     */
    const input = elem as HTMLInputElement;
    switch (elem.getAttribute("type")) {
      case "text":
        return input.value !== input.defaultValue;

      case "radio":
      case "checkbox":
        return input.checked !== input.defaultChecked;

      default:
        return false;
    }
  }
  return false;
}

/**
 *
 *
 *
 *
 */
export function hasChanges(form: HTMLFormElement) {
  return !!Array.from(form.elements).filter(isChanged).length;
}

/**
 *
 *
 */
function clearFormInput(elem: HTMLInputElement): HTMLInputElement {
  const type = elem.getAttribute("type");
  if (type) {
    switch (type.toLowerCase()) {
      case "text":
      case "tel":
      case "number":
        elem.value = "";
        break;
      case "radio":
      case "checkbox":
        if (elem.checked) {
          elem.checked = false;
        }
        break;
    }
  }
  return elem;
}

/**
 *
 *
 *
 */
export function clearAndSubmit(event: Event) {
  const target = event.target as HTMLInputElement;
  const form = target.form;

  if (form) {
    toArray<HTMLInputElement>(form.elements)
      .map(clearFormInput) /*                                 */
      /*                                   */
      .filter(isChanged)
      /*                                                  */
      .forEach((item) => copyLabels(target, item));
  }

  /*     */
  fireFilterSubmit(target);
}

/**
 *
 *
 *
 *
 *
 *
 */
function clearInput(this: HTMLInputElement, event: Event | { currentTarget: HTMLInputElement }) {
  const target = event.currentTarget as HTMLInputElement;

  /*                                                 */
  copyLabels(this, target);

  const input = dereference(target);
  if (input) {
    clearFormInput(input);
  }
}

/**
 *
 *
 *
 */
export function clearAllInputs(event: Event) {
  const target = event.target as HTMLInputElement;

  forEachElement<HTMLInputElement>(JS_CLEAR_INPUT, (elem) =>
    clearInput.call(target, {
      currentTarget: elem,
    }),
  );
}

/**
 *
 *
 *
 *
 *
 */
export function interceptSubmit(event: SubmitEvent) {
  if (event.defaultPrevented) {
    return;
  }

  fireFilterSubmit(event.target as HTMLFormElement);

  /*                         */
  event.preventDefault();
}

/**
 *
 *
 *
 */
export function unlockSubmitButtons(this: HTMLFormElement) {
  forEachElement("[type='submit'][disabled]", (input) => input.removeAttribute("disabled"), this);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function create(form: HTMLFormElement) {
  forEachElement(JS_CLEAR_FORM, (input) => input.addEventListener("click", clearAndSubmit), form);
  forEachElement(JS_CLEAR_INPUT, (input) => input.addEventListener("click", clearInput), form);
  forEachElement(JS_CLEAR_ALL_INPUTS, (input) => input.addEventListener("click", clearAllInputs), form);

  form.addEventListener("submit", interceptSubmit);
  form.addEventListener("input", unlockSubmitButtons, { passive: true, once: true });
}

/**
 *
 *
 */
function submitFiltersNative(event: Event) {
  if (event.defaultPrevented) {
    return;
  }

  const form = event.target as HTMLFormElement;

  /*                                                             */
  /*                                                                                    */
  setTimeout(function () {
    form.submit();
  }, 0);
}

function hasZeroHitsOnAllEntries(formData: FormData, form: HTMLFormElement, excludedKeys: string[]) {
  /*                                                   */
  if (!form.querySelector(".heureka_facetValue__dangerousZeroHits")) {
    return false;
  }
  let totalEntriesCount = 0,
    dangerousZeroHitsCount = 0;
  formData.forEach((value, key) => {
    if (!excludedKeys.includes(key)) {
      if (form.querySelector(`input[name='${key}'][value='${value}'].heureka_facetValue__dangerousZeroHits`)) {
        dangerousZeroHitsCount++;
      }
      totalEntriesCount++;
    }
  });

  return dangerousZeroHitsCount > 0 && totalEntriesCount == dangerousZeroHitsCount;
}

function appendDeliveryStatementValuesTo(formData: FormData) {
  /*                                                                                        */
  const value = formData.get("lieferaussage");
  if (value && typeof value === "string") {
    const values = value.split(",");
    formData.delete("lieferaussage");
    values.forEach((value) => {
      formData.append("lieferaussage", value);
    });
  }
}

/**
 *
 *
 *
 */
function submitFiltersAsync(event: FilterSubmitEvent) {
  if (event.defaultPrevented) {
    return;
  }

  /*                                          */
  /*                                       */
  /*                          */
  /*                                                                                                  */
  /*                    */
  if (!event.detail || !FormData.prototype.entries) {
    submitFiltersNative(event);
    return;
  }

  const form = <HTMLFormElement>event.target;

  const formData = new FormData(form);
  const excludedKeys: string[] = ["sortiertnach", "rule", "group"];
  if (hasZeroHitsOnAllEntries(formData, form, excludedKeys)) {
    formData.forEach((value, key) => {
      if (!excludedKeys.includes(key)) {
        formData.delete(key);
      }
    });
  }

  appendDeliveryStatementValuesTo(formData);
  /*                                                                                                        */
  /*                                                             */
  /*                                                                                    */
  setTimeout(function () {
    eventQBus.emit("ftfind.dresonRule.resolve", { formData: formData });
  }, 0);
}

/**
 *
 *
 *
 */
function updateLayoutField(event: { page?: { l?: string } } | null | undefined) {
  const { page } = event || {};
  const { l = "" } = page || {};

  forEachElement<HTMLInputElement>(".js_formSorting [name='l'][type='hidden']", (input) => (input.value = l));
}

/**
 *
 *
 */
function initializeLayoutField() {
  const datasetElement = document.querySelector(".js_pagingLink") as HTMLElement;
  if (datasetElement) {
    const page = datasetElement.dataset["page"];
    if (page) {
      forEachElement<HTMLInputElement>(
        ".js_formSorting [name='l'][type='hidden']",
        (input) => (input.value = JSON.parse(page)["l"]),
      );
    }
  }
}

/**
 *
 */
export function registerFilterFormListeners(rootElement?: ParentNode | null) {
  forEachElement("form.ts_formFilter", create, rootElement);
}

function registerListeners() {
  registerFilterFormListeners();
}

export function reinitializePaliElements(baseSelector: string) {
  eventQBus.emit("assets.accordion.init", baseSelector);
  eventQBus.emit("assets.dropdown.init", baseSelector);
  eventQBus.emit("assets.label.init", baseSelector);
  eventQBus.emit("assets.formular.init", baseSelector);
  eventQBus.emit("assets.tooltip.init", baseSelector);
}

function initializeSliders() {
  /*                                                   */
  allElements(".heureka_slider__wrapper").forEach((elem) => {
    const slider = Slider.fromId(elem.id);
    if (slider) {
      mount(SvelteSliderOld, {
        target: slider.elem,
        props: {
          id: slider.id,
          wrapperId: elem.id,
          minLabel: slider.minLabel,
          maxLabel: slider.maxLabel,
          minValue: slider.minValue,
          maxValue: slider.maxValue,
          facetName: slider.facetName,
          initialMinValue: slider.initialMinValue,
          initialMaxValue: slider.initialMaxValue,
          availablePreselects: slider.availablePreselects,
          logarithmic: slider.logarithmic,
          delayedActionMillis: slider.delayedActionMillis,
          showDefaultPlaceholders: slider.showDefaultPlaceholders,
          focusFloatsBothLabels: slider.focusFloatsBothLabels,
          hideSlider: slider.hideSlider,
        },
      });
      elem.remove();
    }
  });
}

function initializeSwitches() {
  /*                                            */
  allElements(".ts_heureka_svelteSwitch").forEach((elem) => {
    const featureTracking = {
      ...JSON.parse(elem.dataset.featureTracking || "{}"),
      parentId: elem.dataset.parentId,
    };

    mount(SvelteSwitch, {
      target: elem.parentElement as HTMLElement,
      props: {
        elementId: elem.dataset.facetValueId || "",
        featureTracking: featureTracking,
        facetName: elem.dataset.facetName,
        activeValue: JSON.parse(elem.dataset.activeValue || "{}"),
      },
    });
    elem.remove(); /*                                 */
  });
}

function initializeCustomRanges() {
  /*                                                       */
  mapElements(".ts_heureka_svelteCustomRange", (elem) => {
    const featureTracking = {
      ...JSON.parse(elem.dataset.featureTracking || "{}"),
      parentId: elem.dataset.parentId,
    };

    const parentElement = elem.parentElement as HTMLElement;
    mount(CompositeRange, {
      target: parentElement,
      props: {
        elementId: elem.dataset.facetValueId || "",
        featureTracking: featureTracking,
        activeValue: JSON.parse(elem.dataset.activeValue || "{}"),
        availableRange: JSON.parse(elem.dataset.availableRange || "{}"),
        unit: elem.dataset.unit,
        logarithmic: elem.dataset.logarithmic == "true",
        useSlider: elem.dataset.mayHideSlider != "true" || isMobile(),
        natural: isActive("HEUREKA_1561_FRIENDLY_SLIDERS"),
        facetName: elem.dataset.facetName || "",
      },
    });
    elem.remove();
    return parentElement;
  }).map(CustomRangeInput.init);
}

function reinitializePaliElementsInFilterSection() {
  reinitializePaliElements("#find_filterSection");
  reinitializePaliElements(".heureka_filterButtons");
  /*                                                                                                                                                                                   */
  eventQBus.emit("heureka.sliders.initialised");
}

export default function registerFilterFormActionsHandlers() {
  document.addEventListener("filterSubmit", submitFiltersAsync as EventListener);
  eventQBus.on("ftfind.tilelist.loaded", updateLayoutField);
  eventQBus.on("heureka.filters.loaded", initializeSliders);
  eventQBus.on("heureka.filters.loaded", initializeSwitches);
  eventQBus.on("heureka.filters.loaded", initializeCustomRanges);
  eventQBus.on("heureka.filters.loaded", reinitializePaliElementsInFilterSection);
  eventQBus.on("heureka.filters.loaded", registerListeners);
}

eventLoader.onReady(15, initializeLayoutField);
