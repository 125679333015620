import type { OnFilterSheetCloseDetail, OnFilterSheetOpen, OnFilterSheetOpenDetail } from "./FilterSheetTopics";
import FilterSheet, { SECOND_LEVEL_CHANGE_TRACKING_LABELS } from "./FilterSheet";
import FilterDetailSheet from "./FilterDetailSheet";
import type { DresonRuleResolvedEvent } from "../multifiltering/FilterTypes";
import FilterNavigationSheet, { LOCAL_NAVIGATION } from "./FilterNavigationSheet";
import { eventQBus } from "../types/EventQBus";
import { filterSheetButton, sheetContent } from "./Selectors";
import { OverflowBox } from "../overflowBox/OverflowBox";
import { reinitializePaliElements } from "../filter/FilterFormActions";
import { getActiveSheet } from "../util/NonModuleFunctions";
import { Filter } from "../filter/Filter";

function setInitialScrollPosition(scrollOffset?: number) {
  const elem = filterSheetButton();
  if (elem) {
    if (scrollOffset) {
      elem.dataset.scrollOffset = scrollOffset.toString();
    } else {
      delete elem.dataset.scrollOffset;
    }
  }
}

function onFilterSheetOpen(event?: OnFilterSheetOpen) {
  const { filterId, filterTitle } = event || {};
  if (!filterId) {
    FilterSheet.create().open().initializeSheet();
    return;
  }

  switch (filterId) {
    case LOCAL_NAVIGATION:
      FilterNavigationSheet.create().open().initializeSheet();
      break;
    default:
      FilterDetailSheet.create(filterId, filterTitle).open().initializeSheet();
  }
}

function onSheetChanged() {
  OverflowBox.factory.all(sheetContent()).map((elem) => elem.initializeVisibilities());
}

function onFilterSheetClose() {
  FilterSheet.declare().close();
}

function onFilterSheetClosed() {
  setInitialScrollPosition();
}

function onFilterSheetOpenDetail(event: OnFilterSheetOpenDetail) {
  const { filterId, filterTitle } = event;
  memorizeScrollPositionLevel1();
  FilterSheet.declare().deactivate();
  FilterDetailSheet.declare(filterId, filterTitle)
    .setSheet()
    .initializeSheet()
    .ifActive(() => eventQBus.emit("ftfind.filterSheet.openedDetail", event));
  Filter.updateChangeTrackingLabels(filterId, SECOND_LEVEL_CHANGE_TRACKING_LABELS);
}

function memorizeScrollPositionLevel1() {
  const scrollOffset = getActiveSheet()?.getContent()?.scrollTop;
  setInitialScrollPosition(scrollOffset);
  return scrollOffset;
}

function onFilterSheetCloseDetail(event: OnFilterSheetCloseDetail) {
  const { filterId, filterTitle } = event;
  FilterDetailSheet.declare(filterId, filterTitle).deactivate();
  FilterSheet.declare()
    .setSheet()
    .initializeSheet(false, event.filterId)
    .ifOpen(() => {
      eventQBus.emit("ftfind.filterSheet.closedDetail", event);
    });
}

function onFilterSheetOpenNavigation() {
  memorizeScrollPositionLevel1();
  FilterSheet.declare().deactivate();
  FilterNavigationSheet.declare()
    .setSheet()
    .initializeSheet()
    .ifActive(() => eventQBus.emit("ftfind.filterSheet.openedNavigation"));
}

function onFilterSheetCloseNavigation() {
  FilterNavigationSheet.declare().deactivate();
  FilterSheet.declare()
    .setSheet()
    .initializeSheet()
    .ifOpen(() => {
      eventQBus.emit("ftfind.filterSheet.closedNavigation");
    });
}

function onDresonRuleResolve() {
  FilterSheet.declare().lockContent();
}

function onDresonRuleResolved(data: DresonRuleResolvedEvent) {
  /*                                                          */
  if (!data.mergeOnPi || data.count == 0) {
    /*                 */
    FilterSheet.declare().unlockContent();
  }
}

function onDresonRuleResolveAborted() {
  FilterSheet.addErrorsOnFilterTitleValues();
  FilterSheet.declare().unlockContent();
}

function onFilterSectionLoaded() {
  const scrollOffset = memorizeScrollPositionLevel1();

  FilterSheet.addLocalNavigationTitleValue();
  FilterSheet.addErrorsOnFilterTitleValues();
  FilterSheet.initPartialLocking();

  FilterSheet.declare().setSheet().initializeSheet().unlockContent().setScrollOffset(scrollOffset);

  reinitializePaliElements(".pl_sheet .pl_sheet__content");

  FilterSheet.scrollToRecentFilter();
}

function onFilterSectionLoadAborted() {
  FilterSheet.addInitialLoadError();
  FilterSheet.addLocalNavigationTitleValue();
  FilterSheet.declare().unlockContent();
}

function onLocalNavigationLoaded() {
  FilterSheet.addLocalNavigationTitleValue();
}

export function registerFilterSheetHandlers() {
  eventQBus.on("ftfind.filterSheet.open", onFilterSheetOpen);
  eventQBus.on("ftfind.filterSheet.opened", onSheetChanged);
  eventQBus.on("ftfind.filterSheet.close", onFilterSheetClose);
  eventQBus.on("ftfind.filterSheet.closed", onFilterSheetClosed);

  eventQBus.on("ftfind.filterSheet.openDetail", onFilterSheetOpenDetail);
  eventQBus.on("ftfind.filterSheet.openedDetail", onSheetChanged);
  eventQBus.on("ftfind.filterSheet.closeDetail", onFilterSheetCloseDetail);
  eventQBus.on("ftfind.filterSheet.closedDetail", onSheetChanged);

  /*         */
  eventQBus.on("ftfind.filterSheet.openNavigation", onFilterSheetOpenNavigation);
  eventQBus.on("ftfind.filterSheet.openedNavigation", onSheetChanged);
  eventQBus.on("ftfind.filterSheet.closeNavigation", onFilterSheetCloseNavigation);
  eventQBus.on("ftfind.filterSheet.closedNavigation", onSheetChanged);

  /*      */
  eventQBus.on("ftfind.dresonRule.resolve", onDresonRuleResolve);
  eventQBus.on("ftfind.dresonRule.resolved", onDresonRuleResolved);
  eventQBus.on("ftfind.dresonRule.resolveAborted", onDresonRuleResolveAborted);

  /*        */
  eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
  eventQBus.on("heureka.filters.loadAborted", onFilterSectionLoadAborted);
  eventQBus.on("heureka.filterSection.loadAborted", onFilterSectionLoadAborted);
  eventQBus.on("ftfind.localNavigation.loaded", onLocalNavigationLoaded);
  eventQBus.on("heureka.filters.loaded", onSheetChanged);
}
