import { HeurekaElementFactory } from "../util/HeurekaElementFactory";

const FILTER_RELEVANCIES_ID = "heureka_filter_relevancy";

export class FilterRelevancy {
  private static readonly factory = HeurekaElementFactory.byId(FILTER_RELEVANCIES_ID, FilterRelevancy);

  /*                                                                                                        */
  /*                                                                                                                                           */
  /*                          */

  /*               */
  constructor(private readonly elem: HTMLElement) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return FilterRelevancy.factory.pick(undefined, rootElement);
  }

  /*               */

  static prepareAll(fragment?: DocumentFragment, initialLoad = false) {
    FilterRelevancy.template(fragment)?.prepare(initialLoad, fragment);
  }

  private prepare(initialLoad: boolean, fragment?: DocumentFragment) {
    const oldTemplate = FilterRelevancy.template();
    if (oldTemplate) {
      this.relevantFilterIds = oldTemplate.relevantFilterIds || [];
      this.irrelevantFilterIds = oldTemplate.irrelevantFilterIds || [];
    }
    if (initialLoad) {
      this.relevantFilterIds = FilterRelevancy.template(fragment)?.relevantFilterIds || [];
      this.irrelevantFilterIds = FilterRelevancy.template(fragment)?.irrelevantFilterIds || [];
    }
  }

  /*                       */

  get relevantFilterIds(): string[] {
    const relevantFilterIds = this.elem.dataset.relevantFilterIds;
    return relevantFilterIds ? relevantFilterIds.split(",") : [];
  }

  private set relevantFilterIds(relevantFilterIds) {
    this.elem.dataset.relevantFilterIds = relevantFilterIds.join(",");
  }

  get irrelevantFilterIds(): string[] {
    const irrelevantFilterIds = this.elem.dataset.irrelevantFilterIds;
    return irrelevantFilterIds ? irrelevantFilterIds.split(",") : [];
  }

  private set irrelevantFilterIds(irrelevantFilterIds) {
    this.elem.dataset.irrelevantFilterIds = irrelevantFilterIds.join(",");
  }

  get knownFilterIds(): string[] {
    return this.relevantFilterIds.concat(this.irrelevantFilterIds);
  }

  isIrrelevant(filterId: string): boolean {
    return this.irrelevantFilterIds.includes(filterId);
  }

  isRelevant(filterId: string): boolean {
    return this.relevantFilterIds.includes(filterId);
  }

  isIrrelevantOrUnknown(filterId: string): boolean {
    return !this.isRelevant(filterId);
  }
}
