import { FilterTitles } from "./FilterTitles";
import { FilterTitle } from "./FilterTitle";

/**
 *
 */
export class FilterTitleClickAction implements EventListenerObject {
  /*                  */

  /**
 *
 *
 *
 *
 */
  static on(root: FilterTitles, listener = new FilterTitleClickAction()) {
    root.elem.addEventListener("click", listener, {
      passive: true,
    });
  }

  /*                 */

  /**
 *
 *
 *
 */
  handleEvent(event: Event) {
    const filterTitle = FilterTitle.factory.closest(event.target);
    if (!filterTitle) {
      return;
    }

    if (filterTitle.onlyTitleMode) {
      filterTitle.openDetailSheet();
    } else {
      if (event.target === filterTitle.moreButton) {
        filterTitle.openDetailSheet("open_show_more");
      }
    }
  }
}
