import type { SliderHost } from "./SliderHost.ts";
import type { SliderElement } from "./SliderElement.ts";

export function registerListeners(slider: SliderHost, sliderElement: SliderElement) {
  const canHover = window.matchMedia("(hover: hover)").matches;

  const setNewValueForRelatedElement = (
    inputElement: HTMLInputElement,
    value: number,
    updateNumberInputs = true,
  ): void => {
    const calculatedValue = slider.calcValue(Number(value)).toFixed(0);
    const calculatedPosition = slider.calcPosition(Number(value)).toString();

    /*                              */
    if (!!sliderElement.rangeInputMin && inputElement.isSameNode(sliderElement.numberInputMin)) {
      if (value >= slider.minVal) {
        sliderElement.numberInputMax.min = value.toString();
        sliderElement.rangeInputMin.value = calculatedPosition;
      }
    }

    /*                              */
    if (!!sliderElement.rangeInputMax && inputElement.isSameNode(sliderElement.numberInputMax)) {
      if (value <= slider.maxVal) {
        sliderElement.numberInputMin.max = value.toString();
        sliderElement.rangeInputMax.value = calculatedPosition;
      }
    }

    /*                             */
    if (updateNumberInputs && !!sliderElement.numberInputMin && inputElement.isSameNode(sliderElement.rangeInputMin)) {
      sliderElement.numberInputMin.value = calculatedValue;
      sliderElement.numberInputMin.setAttribute("value", sliderElement.numberInputMin.value);
      sliderElement.numberInputMax.min = calculatedValue;
    }

    /*                             */
    if (updateNumberInputs && !!sliderElement.numberInputMax && inputElement.isSameNode(sliderElement.rangeInputMax)) {
      sliderElement.numberInputMax.value = calculatedValue;
      sliderElement.numberInputMax.setAttribute("value", sliderElement.numberInputMax.value);
      sliderElement.numberInputMin.max = calculatedValue;
    }
  };

  const setRangeElementsToNewPositions = (rangeElement: HTMLInputElement): void => {
    /*                     */
    /*                             */
    const scaledPos = (100 / slider.maxPos) * Number(rangeElement.value);

    if (rangeElement.isSameNode(sliderElement.rangeInputMin)) {
      sliderElement.inverseLeft.style.width = `${scaledPos}%`;
      sliderElement.range.style.left = `${scaledPos}%`;
      sliderElement.thumbMin.style.left = `calc(${scaledPos}% - (24px*(${scaledPos} / 100)))`;
      sliderElement.numberInputMin.classList.remove("invalid");
    }

    if (rangeElement.isSameNode(sliderElement.rangeInputMax)) {
      sliderElement.inverseRight.style.width = `${100 - scaledPos}%`;
      sliderElement.range.style.right = `${100 - scaledPos}%`;
      sliderElement.thumbMax.style.left = `calc(${scaledPos}% - (24px * (${scaledPos} / 100)))`;
      sliderElement.numberInputMax.classList.remove("invalid");
    }
    /**/
  };

  function handleNumberInputChangeEvent(inputElement: HTMLInputElement): (this: HTMLInputElement) => void {
    const res = function handler(this: HTMLInputElement): void {
      if (this.value && this.validity.valid) {
        setNewValueForRelatedElement(this, Number(this.value));
        setRangeElementsToNewPositions(inputElement);
      }
    };
    return res;
  }

  function handleRangeInputEvent(this: HTMLInputElement, ev: Event): void {
    const currentValueMin = this.isSameNode(sliderElement.rangeInputMin)
      ? slider.calcValue(Number(this.value)).toFixed(0)
      : sliderElement.getNumberInputMinValue();
    const currentValueMax = this.isSameNode(sliderElement.rangeInputMax)
      ? slider.calcValue(Number(this.value)).toFixed(0)
      : sliderElement.getNumberInputMaxValue();

    if (Number(currentValueMax) < Number(currentValueMin)) {
      ev.preventDefault();
      this.value = this.isSameNode(sliderElement.rangeInputMin)
        ? sliderElement.rangeInputMax.value
        : sliderElement.rangeInputMin.value;

      return;
    }

    setNewValueForRelatedElement(this, Number(this.value));
    setRangeElementsToNewPositions(this);
  }

  const closerTo = (value: number): HTMLInputElement => {
    const diffToMin = Math.abs(value - Number(sliderElement.rangeInputMin.value));
    const diffToMax = Math.abs(value - Number(sliderElement.rangeInputMax.value));

    return diffToMin < diffToMax ? sliderElement.rangeInputMin : sliderElement.rangeInputMax;
  };

  const registerEventHandlingForThumbDrag = (): void => {
    /*                               */
    sliderElement.rangeInputMin.addEventListener("pointerdown", () => {
      sliderElement.thumbMin.style.transform = "scale(calc(32 / 24))";
    });

    sliderElement.rangeInputMax.addEventListener("pointerdown", () => {
      sliderElement.thumbMax.style.transform = "scale(calc(32 / 24))";
    });

    /*                             */
    sliderElement.rangeInputMin.addEventListener("pointerup", () => {
      sliderElement.thumbMin.style.transform = "initial";
      sliderElement?.rangeInputMin?.parentNode?.insertBefore(sliderElement.rangeInputMax, sliderElement.rangeInputMin);
    });

    sliderElement.rangeInputMax.addEventListener("pointerup", () => {
      sliderElement.thumbMax.style.transform = "initial";
      sliderElement?.rangeInputMax?.parentNode?.insertBefore(sliderElement.rangeInputMin, sliderElement.rangeInputMax);
    });
  };

  const registerEventHandlingForRangeTrackClicks = (): void => {
    sliderElement.inverseLeft.addEventListener("click", () => {
      sliderElement.rangeInputMin.style.pointerEvents = "none";
    });

    sliderElement.inverseLeft.addEventListener("mouseenter", () => {
      sliderElement.rangeInputMin.addEventListener("mouseleave", () => {
        sliderElement.rangeInputMin.style.pointerEvents = "none";
      });

      sliderElement.rangeInputMin.addEventListener("click", () => {
        sliderElement.rangeInputMin.style.pointerEvents = "none";
      });

      sliderElement.rangeInputMin.style.pointerEvents = "all";

      sliderElement.thumbMax.style.zIndex = "4";
      sliderElement.range.style.zIndex = "4";

      sliderElement.thumbMax.addEventListener("mouseenter", () => {
        sliderElement.rangeInputMin.style.pointerEvents = "none";
        sliderElement.thumbMax.style.zIndex = "initial";
        sliderElement.range.style.zIndex = "initial";
      });
      sliderElement.range.addEventListener("mouseenter", () => {
        sliderElement.rangeInputMin.style.pointerEvents = "none";
        sliderElement.thumbMax.style.zIndex = "initial";
        sliderElement.range.style.zIndex = "initial";
      });
    });

    sliderElement.inverseRight.addEventListener("click", () => {
      sliderElement.rangeInputMax.style.pointerEvents = "none";
    });

    sliderElement.inverseRight.addEventListener("mouseenter", () => {
      sliderElement.rangeInputMax.addEventListener("mouseleave", () => {
        sliderElement.rangeInputMax.style.pointerEvents = "none";
      });

      sliderElement.rangeInputMax.addEventListener("click", () => {
        sliderElement.rangeInputMax.style.pointerEvents = "none";
      });

      sliderElement.rangeInputMax.style.pointerEvents = "all";

      sliderElement.thumbMin.style.zIndex = "4";
      sliderElement.range.style.zIndex = "4";

      sliderElement.thumbMin.addEventListener("mouseenter", () => {
        sliderElement.rangeInputMax.style.pointerEvents = "none";
        sliderElement.thumbMin.style.zIndex = "initial";
        sliderElement.range.style.zIndex = "initial";
      });
      sliderElement.range.addEventListener("mouseenter", () => {
        sliderElement.rangeInputMax.style.pointerEvents = "none";
        sliderElement.thumbMin.style.zIndex = "initial";
        sliderElement.range.style.zIndex = "initial";
      });
    });

    sliderElement.range.addEventListener("click", (e) => {
      const minMaxDiff = Number(sliderElement.rangeInputMax.value) - Number(sliderElement.rangeInputMin.value);

      const approxVal = Math.round(
        Number(sliderElement.rangeInputMin.value) + Number(minMaxDiff * (e.offsetX / sliderElement.range.clientWidth)),
      );

      const closerElement = closerTo(approxVal);

      setNewValueForRelatedElement(closerElement, approxVal);

      closerElement.value = approxVal.toFixed(0);

      setRangeElementsToNewPositions(closerElement);
    });
  };

  /*                                            */
  sliderElement.numberInputMin.addEventListener("change", handleNumberInputChangeEvent(sliderElement.rangeInputMin));
  sliderElement.numberInputMax.addEventListener("change", handleNumberInputChangeEvent(sliderElement.rangeInputMax));

  /*                                           */
  sliderElement.rangeInputMin.addEventListener("input", handleRangeInputEvent);
  sliderElement.rangeInputMax.addEventListener("input", handleRangeInputEvent);

  registerEventHandlingForThumbDrag();

  /*                                                                                         */
  if (canHover) {
    registerEventHandlingForRangeTrackClicks();
  }

  /*                 */
  [sliderElement.rangeInputMin, sliderElement.rangeInputMax].forEach((rangeInput) => {
    setNewValueForRelatedElement(rangeInput, Number(rangeInput.value), false);
    setRangeElementsToNewPositions(rangeInput);
  });
}
