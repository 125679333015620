import { HeurekaElementFactory } from "../util/HeurekaElementFactory";

const AV_CONTENT_ID = "avContent";

export class AvContent {
  static readonly factory = HeurekaElementFactory.byId(AV_CONTENT_ID, AvContent);

  /*               */
  constructor(readonly avContent: HTMLElement) {}

  /*                  */

  static instance(root?: NonElementParentNode) {
    return AvContent.factory.byId(AV_CONTENT_ID, root);
  }

  static pageRule(root?: NonElementParentNode) {
    return this.instance(root)?.rule;
  }

  /*               */

  /*                       */

  get rule(): string | undefined {
    return this.avContent.dataset.rule;
  }
}
