import { eventQBus } from "../types/EventQBus";
import { clone, element } from "../util/Utils";
import { registerFilterFormListeners } from "./FilterFormActions";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { OverflowBox, OverflowBoxItem } from "../overflowBox/OverflowBox";
import { FacetValueTag } from "./FacetValueTag";
import { referencedFeature, updateLabel } from "../tracking/FeatureTracking";
import StringHelper from "../util/StringHelper";
import type { Appendable } from "../util/Appendable";
import type { FacetValue } from "./FacetValue";
import { isActive } from "../toggle/Toggle";

const CLASS_FACET_VALUE_TAGS = "find_facetValueTags";
const SELECTOR_FACET_VALUE_TAGS = `.${CLASS_FACET_VALUE_TAGS}`;
const CLASS_DESELECT_FACET_VALUE_TAGS = "find_facetValueTags__item--clear";
const SELECTOR_DESELECT_FACET_VALUE_TAGS = `.${CLASS_DESELECT_FACET_VALUE_TAGS}`;
const SELECTOR_TEMPLATE_FACET_VALUE_TAGS = `#san_facetValueTags ${SELECTOR_FACET_VALUE_TAGS}`;

export class FacetValueTags implements Appendable {
  static readonly factory = HeurekaElementFactory.byClass(CLASS_FACET_VALUE_TAGS, FacetValueTags);

  /*               */

  constructor(readonly tags: HTMLElement) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return FacetValueTags.factory.pick(SELECTOR_TEMPLATE_FACET_VALUE_TAGS, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", FacetValueTags.initAll);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FacetValueTags.emitSelectedFacetValues(event.rule, rootElement);
    FacetValueTags.factory.forEach((form) => form.init(), rootElement);
  }

  protected init() {
    registerFilterFormListeners(this.tags);
    return this;
  }

  /*                       */

  get facetValueTags(): FacetValueTag[] {
    return FacetValueTag.factory.all(this.tags);
  }

  get deselectAllButton(): HTMLElement | null {
    return element(SELECTOR_DESELECT_FACET_VALUE_TAGS, this.tags);
  }

  appendTo(elem: HTMLElement): void {
    elem.appendChild(this.tags);
    this.recomputeSize();
    this.updateTrackingLabels();
  }

  clone() {
    return FacetValueTags.factory.create(clone(this.tags)).init();
  }

  /*                 */

  private static emitSelectedFacetValues(rule: string, rootElement?: ParentNode) {
    const facetValueTags = FacetValueTags.template(rootElement)?.facetValueTags;
    if (facetValueTags) {
      const selectedValues = facetValueTags.map((t) => t.title).flatMap((f) => (f ? [f] : []));
      eventQBus.emit("heureka.facetValueTags.loaded", {
        rule: rule,
        tags: selectedValues,
      });
    }
  }

  /*       */

  private static updateVisibilityTrackingLabels(facetValueTag: FacetValueTag, index: number) {
    const deselect = isActive("HEUREKA_1367_REFACTOR_FACET_VALUE_TAGS") ? facetValueTag.deselect : facetValueTag.button;
    if (deselect) {
      const facetValue = referencedFeature(deselect);
      if (facetValue) {
        const isVisible = OverflowBoxItem.isVisibleIfCollapsed(deselect);
        updateLabel(facetValue, "san_FacetValueTagVisible", StringHelper.toBooleanString(isVisible));
        updateLabel(facetValue, "san_FacetValueTagPosition", (index + 1).toString());
      }
    }
  }

  private updateTrackingLabels() {
    FacetValueTag.factory.forEach(FacetValueTags.updateVisibilityTrackingLabels, this.tags);
  }

  recomputeSize() {
    OverflowBox.factory.declare(this.tags)?.initializeCollapsedState(true);
  }

  findIndexOf(predicate: (facetValueTag: FacetValueTag) => boolean): number {
    return this.facetValueTags.findIndex(predicate);
  }

  removeFacetValueTagsOf(predicate: (facetValueTag: FacetValueTag) => boolean): void {
    this.facetValueTags.filter(predicate).forEach((facetValueTag) => facetValueTag.facetValueTag.remove());
  }

  appendFacetValues(values: FacetValue[], index?: number) {
    let appendPosition = index !== undefined ? index : this.tags.children.length;
    values.forEach((value) => {
      const liElem = document.createElement("li");
      liElem.classList.add("find_facetValueTags__item", "heureka_overflowBox__item");
      liElem.append(value.label);
      this.tags.insertBefore(liElem, this.tags.children[appendPosition]);
      appendPosition++;
    });
  }
}
