import type { BreakpointOptions } from "@otto-ec/global-resources/breakpoint";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";

export function onBreakpointChange(bp: keyof BreakpointOptions, listener: (event: MediaQueryListEvent) => void) {
  const breakpoints: BreakpointOptions = breakpoint.breakpoints;
  if (breakpoints) {
    breakpoints[bp].addListener(listener);
  }
}

export function isMobile() {
  return breakpoint.isBreakpointActive(["s", "m"]);
}

export function isDesktop() {
  return breakpoint.isBreakpointActive(["l", "xl"]);
}
