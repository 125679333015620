<script lang="ts">
  interface Props {
    isPrefix: boolean;
    inputIcon: string;
  }

  let { isPrefix, inputIcon }: Props = $props();
  const icon = inputIcon?.match(/^pl_icon_.*/);
</script>

{#if icon}
  <svg class="pl_icon{isPrefix ? ' pl_input__prefix' : ' pl_input__suffix'}" role="img">
    <use href="/assets-static/icons/{icon[0]}.svg#{icon[0]}" xlink:href="/assets-static/icons/{icon[0]}.svg#{icon[0]}"
    ></use>
  </svg>
{/if}
