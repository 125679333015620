export abstract class AbstractFacetValue {
  abstract label: HTMLLabelElement | null;
  abstract input: HTMLInputElement | null;
  abstract span: HTMLSpanElement | null;

  transformToCheckbox() {
    return this.#transformChipCheckbox(false);
  }

  transformToChip(useWhiteBackgroundColor = false) {
    return this.#transformChipCheckbox(true, useWhiteBackgroundColor);
  }

  #transformChipCheckbox(toChip = true, useWhiteBackgroundColor = false) {
    if (this.input && this.span) {
      this.label?.classList.toggle("pl_checkbox", !toChip);
      this.label?.classList.toggle("pl_chip100", toChip);
      this.label?.classList.toggle("pl_chip100--white", toChip && useWhiteBackgroundColor);
      this.label?.classList.toggle("pl_chip100--grey", toChip && !useWhiteBackgroundColor);
      this.label?.classList.toggle("pl_chip100--fixes", toChip);
      this.input.classList.toggle("pl_checkbox__element", !toChip);
      this.span.classList.toggle("pl_checkbox__label", !toChip);
    }
    return this;
  }
}
