import { eventQBus } from "../types/EventQBus";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { FilterAccordions } from "./FilterAccordions";
import { FilterTitles } from "./FilterTitles";
import { Filter } from "./Filter";

const ID_SORTED_FILTERS_BY_QUERY = "heureka_initial_sortByQuery";

export class FilterSortingOld {
  private static readonly factory = HeurekaElementFactory.byId(ID_SORTED_FILTERS_BY_QUERY, FilterSortingOld);

  /*               */
  constructor(private readonly elem: HTMLElement) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return FilterSortingOld.factory.pick(undefined, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", FilterSortingOld.initAll);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FilterSortingOld.template(rootElement)?.init();
  }

  protected init() {
    return this;
  }

  static prepareAll(fragment: DocumentFragment) {
    FilterSortingOld.template(fragment)?.prepare(fragment);
  }

  private prepare(fragment: DocumentFragment) {
    const oldTemplate: FilterSortingOld | undefined = FilterSortingOld.template();
    if (oldTemplate && oldTemplate.values) {
      this.values = oldTemplate.values;
    }
    this.rearrangeForcedFilters(fragment);
    FilterAccordions.template(fragment)?.sortValuesOld(this);
    FilterTitles.template(fragment)?.sortValuesOld(this);
  }

  private rearrangeForcedFilters(fragment: DocumentFragment) {
    const parsedValues = this.parsedValues;
    if (parsedValues) {
      const filters = Filter.factory.all(fragment);
      filters
        .filter((filter) => filter.forceIndex !== undefined)
        .forEach((filter) => FilterSortingOld.move(filter.id, parsedValues, filter.forceIndex!));
      this.values = `["${parsedValues.join('", "')}"]`;
    }
  }

  private static move(filter: string, filters: string[], toIndex: number) {
    filters.splice(filters.indexOf(filter), 1);
    filters.splice(toIndex, 0, filter);
  }

  /*                       */

  private get values(): string | undefined {
    return this.elem.dataset.sortedFilterIds;
  }

  private set values(sortedFilterIds) {
    this.elem.dataset.sortedFilterIds = sortedFilterIds;
  }

  get parsedValues(): string[] | undefined {
    const { values } = this;
    return values && JSON.parse(values);
  }

  private evaluateAndCollectNonSortedFilters(filters: HTMLElement[], sortedFilters: string[]) {
    const nonSortedFilters: HTMLElement[] = [];
    filters.forEach((elem) => {
      const filterId = elem.dataset.filterId;
      if (filterId && !sortedFilters.includes(filterId)) {
        nonSortedFilters.push(elem);
      }
    });
    return nonSortedFilters;
  }

  private static getSixtyPercentFilterPosition(filters: HTMLElement[]): number {
    const length = filters.filter((filter) => !filter.hidden).length;
    return (length && Math.ceil(length * 0.6)) || filters.length;
  }

  private static removeElements(filtersToSort: HTMLElement[], deleteCount: number) {
    filtersToSort.splice(0, deleteCount);
  }

  private static mergeElements(filtersToSort: HTMLElement[], nonSortedFilters: HTMLElement[], index: number) {
    const mergeInPlace = (elements: HTMLElement[], elementsToMerge: HTMLElement[], i = 0) => {
      return elements.splice(i, 0, ...elementsToMerge);
    };
    mergeInPlace(filtersToSort, nonSortedFilters, index + 1);
  }

  private sortFilters(filters: HTMLElement[], sortedFilters: string[]) {
    const filtersToSort = Array.prototype.slice.call(filters, 0);
    filtersToSort.sort(
      (filter1, filter2) =>
        sortedFilters.indexOf(filter1.dataset.filterId) - sortedFilters.indexOf(filter2.dataset.filterId),
    );
    return filtersToSort;
  }

  public sortFiltersByQuery(filters: HTMLElement[]) {
    const { parsedValues } = this;
    if (parsedValues) {
      /*                      */
      parsedValues.unshift("localNavigation");

      const nonSortedFilters = this.evaluateAndCollectNonSortedFilters(filters, parsedValues);
      /*                                                             */
      const filtersToSort = this.sortFilters(filters, parsedValues);
      /*                                                      */
      FilterSortingOld.removeElements(filtersToSort, nonSortedFilters.length);
      /*                                  */
      FilterSortingOld.mergeElements(
        filtersToSort,
        nonSortedFilters,
        FilterSortingOld.getSixtyPercentFilterPosition(filtersToSort), /*                                                */
      );
      return filtersToSort;
    }
  }
}
