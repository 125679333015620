const FILTER_CONTEXT_ID = "heureka_filterContextId";

export default class FilterContextId {
  /*               */

  private constructor(
    public readonly id: string = "default",
    public readonly cat?: string,
  ) {}

  /*                  */

  static create(id?: string, cat?: string) {
    return new FilterContextId(id, cat);
  }

  static declare(node: Document | DocumentFragment = document): FilterContextId {
    const elem = node.getElementById(FILTER_CONTEXT_ID);
    const id = elem?.dataset.contextId;
    const cat = elem?.dataset.dominantCategory;
    return this.create(id, cat);
  }

  /*                       */

  isDefault() {
    return this.id === "default";
  }

  getNonDefaultId(): string | undefined {
    return !this.isDefault() ? this.id : undefined;
  }
}

export function filterContextId(node: Document | DocumentFragment = document) {
  return FilterContextId.declare(node).id;
}
