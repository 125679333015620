export default class StringHelper {
  private static rExps = [
    { re: "[A\xC0-\xC6]", ch: "A" },
    { re: "[a\xE0-\xE6]", ch: "a" },
    { re: "[E\xC8-\xCB]", ch: "E" },
    { re: "[e\xE8-\xEB]", ch: "e" },
    { re: "[I\xCC-\xCF]", ch: "I" },
    { re: "[i\xEC-\xEF]", ch: "i" },
    { re: "[O\xD2-\xD6]", ch: "O" },
    { re: "[o\xF2-\xF8]", ch: "o" },
    { re: "[U\xD9-\xDC]", ch: "U" },
    { re: "[u\xF9-\xFC]", ch: "u" },
    { re: "[N\xD1]", ch: "N" },
    { re: "[n\xF1]", ch: "n" },
  ];

  public static replaceVowelAccentsWithRegExp(input: string): string {
    let newString = input;

    for (let i = 0, len = StringHelper.rExps.length; i < len; i++) {
      newString = newString.replace(new RegExp(StringHelper.rExps[i].re, "g"), StringHelper.rExps[i].re);
    }
    return newString;
  }

  public static regexEscapeLiteral(input: string): string {
    return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  public static regexEscapeGreaterThan(input: string): string {
    return input.replaceAll(">", "\\>");
  }

  /*                                                                                              */
  public static toBooleanString(input: boolean): "true" | "false" {
    return input ? "true" : "false";
  }
}
