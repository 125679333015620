<script module lang="ts">
  let counter = 0;
</script>

<script lang="ts">
  import type { FacetValue, Interval } from "../FacetValue";
  import type { FeatureTrackingReference } from "../FeatureTrackingReference";
  import { addFeatureTrackingReference } from "../Tracking.ts";

  interface Props {
    unit: string | undefined;
    activeValue: FacetValue;
    availableRange: Required<Interval>;
    featureTrackingReference?: FeatureTrackingReference | undefined;
  }

  let { unit, activeValue = $bindable(), availableRange, featureTrackingReference = undefined }: Props = $props();

  const minInputId = `hc_minMax__min${counter}`;
  const maxInputId = `hc_minMax__max${counter++}`;

  let valueMin = $derived(activeValue.range.from || availableRange.from);
  let valueMax = $derived(activeValue.range.to || availableRange.to);

  let labelSuffix = (unit && ` (${unit})`) || "";

  function onUpdateMinValue(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const from = event.target.valueAsNumber;
      activeValue.range.from = isNaN(from) ? undefined : from;
    }
  }

  function onUpdateMaxValue(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const to = event.target.valueAsNumber;
      activeValue.range.to = isNaN(to) ? undefined : to;
    }
  }

  $effect(() => {
    activeValue.value = `${activeValue.range.from || "_"},${activeValue.range.to || "_"}`;
  });
</script>

<div class="hc_minMax pl_input--floating-label pl_input--hide-placeholders">
  <span class="js-pl_input pl_input">
    <input
      id={minInputId}
      maxlength="5"
      class="pl_input__field"
      type="number"
      inputmode="numeric"
      placeholder={availableRange.from.toFixed()}
      max={valueMax}
      value={activeValue.range.from}
      data-ts-feature-filter-method="interval"
      onchange={onUpdateMinValue}
      use:addFeatureTrackingReference={featureTrackingReference}
    />
    <label for={minInputId} class="js-pl_label pl_label">Von{labelSuffix}</label>
  </span>
  <span class="js-pl_input pl_input">
    <input
      id={maxInputId}
      maxlength="5"
      class="pl_input__field"
      type="number"
      inputmode="numeric"
      placeholder={availableRange.to.toFixed()}
      min={valueMin}
      value={activeValue.range.to}
      data-ts-feature-filter-method="interval"
      onchange={onUpdateMaxValue}
      use:addFeatureTrackingReference={featureTrackingReference}
    />
    <label for={maxInputId} class="js-pl_label pl_label">Bis{labelSuffix}</label>
  </span>
</div>

<style lang="scss">
  .hc_minMax {
    margin: 0;
    width: 100%;
    display: flex;
    gap: var(--oc-semantic-spacing-50);

    > * {
      flex: 1;
      margin: 0;
    }
  }
</style>
