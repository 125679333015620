import { forEachElement } from "../util/Utils";
import { eventQBus } from "../types/EventQBus";
import { updateLabel } from "../tracking/FeatureTracking";
import { ExpanderItem } from "./Expander";
import StringHelper from "../util/StringHelper";
import { isDesktop } from "../util/Breakpoint";

function updateTrackingLabels(alwaysVisible: boolean, facetValue: HTMLElement) {
  const isVisible = alwaysVisible || ExpanderItem.isVisibleIfCollapsed(facetValue);
  updateLabel(facetValue, "san_FacetValueInitialVisible", StringHelper.toBooleanString(isVisible));
}

function onFilterSectionLoaded() {
  const alwaysVisible = isDesktop();
  forEachElement(
    "#heureka_filterTitles .heureka_expander [data-ts-feature-name='Facet-Value']",
    updateTrackingLabels.bind(undefined, alwaysVisible),
  );
}

export function registerSheetExpanderTrackingHandlers() {
  eventQBus.on("ftfind.filterSheet.opened", onFilterSectionLoaded);
  eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
}
