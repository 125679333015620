import { childElements, moveAllChildren } from "../util/Utils";
import type { Sheet } from "@otto-ec/global-pattern";
import { localNavigation, sheetContent } from "./Selectors";
import { setLegacyLabels } from "../tracking/FeatureTracking";
import { eventQBus } from "../types/EventQBus";
import { getActiveSheet } from "../util/NonModuleFunctions";
import { createSheetTemplate } from "./PaliSheet";
import type { SheetAPI } from "@otto-ec/global-pattern/types/700_Layer_und_Popup/400_pl_sheet/sheetTypes";

export const LOCAL_NAVIGATION = "localNavigation";

export default class FilterNavigationSheet {
  constructor(private readonly sheet?: Sheet | SheetAPI) {
    this.sheet = sheet;
  }

  public static create() {
    return new FilterNavigationSheet(FilterNavigationSheet.newSheet());
  }

  public static declare() {
    return new FilterNavigationSheet(getActiveSheet());
  }

  private static newSheet() {
    return createSheetTemplate(childElements(localNavigation()) || [], null, true);
  }

  setSheet() {
    if (this.sheet) {
      /*                                                                                               */
      /*                                         */
      /*                                                                              */
      this.sheet.setContent(childElements(localNavigation()) || []);
      this.sheet.setMenuBar([]);
    }
    return this;
  }

  initializeSheet() {
    if (this.sheet) {
      this.sheet.setTitle("Kategorien");

      const content = this.sheet.getContent();
      if (content) {
        FilterNavigationSheet.prepareContent(content);
      }

      this.sheet.setBackButton(FilterNavigationSheet.onClickNavigationBack, "");
      const backButton = this.sheet.getBackButton();
      if (backButton) {
        FilterNavigationSheet.prepareBackButton(backButton);
      }

      this.sheet.hideMenuBar();
    }
    return this;
  }

  public ifActive(callback: (sheet: Sheet | SheetAPI) => void) {
    if (this.sheet) {
      callback(this.sheet);
    }
  }

  public open() {
    if (this.sheet) {
      this.sheet.open();
    }
    return this;
  }

  public deactivate() {
    const source = sheetContent();
    const destination = localNavigation();

    if (source) {
      delete source.dataset.filterId;
      delete source.dataset.tsLabels;
    }

    moveAllChildren(source, destination);
  }

  private static prepareBackButton(backButton: HTMLElement) {
    backButton.classList.add("find_filterSheet__backButton", "js_filterSheetAction");

    setLegacyLabels(backButton, {
      san_FilterFoldoutType: LOCAL_NAVIGATION,
      san_FilterFoldoutActivity: "close",
      san_FacetChangeNothing: "back",
    });
  }

  private static onClickNavigationBack() {
    eventQBus.emit("ftfind.filterSheet.closeNavigation");
  }

  private static prepareContent(content: HTMLElement) {
    content.scrollTop = 0;
    content.dataset.filterId = LOCAL_NAVIGATION;

    setLegacyLabels(content, {
      san_FilterFoldoutType: LOCAL_NAVIGATION,
      san_FilterFoldoutActivity: "close",
      san_FacetChangeNothing: "view_selection",
    });
  }
}
