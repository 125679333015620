import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { Facet } from "./Facet";
import { all } from "../util/Reductions";
import { Filter } from "./Filter";
import type { FacetVisibilityAware } from "./FacetVisibilityAware";

const FACET_GROUP_CLASS = "find_aggregatedFacet";
const FACET_GROUP_ACTIVE_CLASS = `${FACET_GROUP_CLASS}--active`;

export class FacetGroup implements FacetVisibilityAware {
  static readonly factory = HeurekaElementFactory.byClass(FACET_GROUP_CLASS, FacetGroup);

  /*               */
  constructor(readonly facetGroup: HTMLElement) {}

  /*                  */

  static groupId(id: string, root?: ParentNode | null) {
    return FacetGroup.factory.pick(`.${FACET_GROUP_CLASS}--${id}`, root);
  }

  /*               */

  /*                       */

  get name() {
    const classList = this.facetGroup.classList;
    return Array.from(classList)
      .find((s) => s.startsWith("find_aggregatedFacet--"))
      ?.substring("find_aggregatedFacet--".length);
  }

  hasName(name: string) {
    return this.facetGroup.classList.contains(`find_aggregatedFacet--${name}`);
  }

  get filter(): Filter | undefined {
    return Filter.factory.closest(this.facetGroup);
  }

  get facets(): Facet[] {
    return Facet.factory.all(this.facetGroup);
  }

  get mayHide() {
    return this.facets.map((facet) => facet.hidden).reduce(all);
  }

  get hidden(): boolean {
    return this.facetGroup.hidden;
  }

  set hidden(hidden) {
    this.facetGroup.hidden = hidden;
  }

  get active(): boolean {
    return this.facetGroup.classList.contains(FACET_GROUP_ACTIVE_CLASS);
  }

  set active(active) {
    this.facetGroup.classList.toggle(FACET_GROUP_ACTIVE_CLASS, active);
  }

  updateVisibility(shouldHide: boolean) {
    const hidden = shouldHide && this.mayHide;
    const { name } = this;
    const facetGroupSelect = this.filter?.facetGroupSelect;
    if (facetGroupSelect && name) {
      facetGroupSelect.updateVisibility(name, hidden);
      const isActive = this.active;
      this.hidden = isActive ? false : hidden;
    } else {
      this.hidden = hidden;
    }
  }

  /**
 *
 *
 *
 *
 */
  toggleVisibility(force = this.active) {
    const wasActive = this.active;
    this.active = force;
    return wasActive !== this.active;
  }
}
