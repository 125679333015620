import { eventQBus } from "../types/EventQBus";

function onFilterSheetOpened() {
  eventQBus.emit("ftfind.history.merge", {
    topic: "ftfind.filterSheet.close",
    data: {},
  });

  eventQBus.emit("ftfind.history.push", {
    topic: "ftfind.filterSheet.open",
    data: {},
    ephemeral: true,
  });
}

function onFilterSheetClosed() {
  /*                                                                              */
  eventQBus.emit("ftfind.history.shift", {
    force: false,
  });
}

export function registerFilterSheetHistory() {
  eventQBus.on("ftfind.filterSheet.opened", onFilterSheetOpened);
  eventQBus.on("ftfind.filterSheet.closed", onFilterSheetClosed);
}
