import { clone, forEachElement, getElementById } from "../util/Utils";
import { eventQBus } from "../types/EventQBus";
import { readLegacyLabel, updateLabel, updateLegacyLabel } from "../tracking/FeatureTracking";
import { filterSheetButton, menuBarButtonForm, quickFilterButton } from "../filtersheet/Selectors";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { Facet } from "./Facet";
import { OverflowBoxContainer } from "../overflowBox/OverflowBox";
import { PopularFacetValue } from "./PopularFacetValue";
import { Filter } from "./Filter";
import tracker from "../tracking/Tracker";

const PLACEHOLDER_ID = "heureka_popularFacetValues--placeholder";
const FACET_VALUE_TAG_FORM_SELECTOR = ".find_facetValueTags form";
const POPULAR_FACET_VALUES_WRAPPER_CLASS = "ts_heureka_popularFacetValuesWrapper";
const STATIC_POPULAR_FACET_VALUES_CLASS = "heureka_popularFacetValuesWrapper--static";
const CONTENT_CLASS = "find_facet__content--popularValues";
const CONTENT_SELECTOR = `.${CONTENT_CLASS}`;
const FILTER_IDS_TO_EXCLUDE_FROM_DUPLICATES_REMOVAL = ["groesse"];
const FACET_NAMES_TO_EXCLUDE_FROM_DUPLICATES_REMOVAL = ["us-groessen"];

export class PopularFacetValuesWrapper {
  static readonly factory: HeurekaElementFactory<PopularFacetValuesWrapper, keyof HTMLElementTagNameMap> =
    HeurekaElementFactory.byClass(POPULAR_FACET_VALUES_WRAPPER_CLASS, PopularFacetValuesWrapper);

  /*               */
  constructor(readonly popularFacetValuesWrapper: HTMLElement) {}

  static create(elem: HTMLElement) {
    return new PopularFacetValuesWrapper(elem);
  }

  /*                       */
  get hidden(): boolean {
    return this.popularFacetValuesWrapper.hidden;
  }

  set hidden(hidden: boolean) {
    this.popularFacetValuesWrapper.hidden = hidden;
  }

  get facet() {
    return Facet.factory.closest(this.popularFacetValuesWrapper);
  }

  get filter() {
    return Filter.factory.closest(this.popularFacetValuesWrapper);
  }

  get facetValues(): PopularFacetValue[] {
    return PopularFacetValue.factory.all(this.popularFacetValuesWrapper);
  }

  get visibleAndNotCheckedFacetValues(): PopularFacetValue[] {
    return PopularFacetValue.factory
      .all(this.popularFacetValuesWrapper)
      .filter((popularFacetValue) => popularFacetValue.visibleAndNotChecked);
  }

  get visibleFacetValues(): PopularFacetValue[] {
    return PopularFacetValue.factory
      .all(this.popularFacetValuesWrapper)
      .filter((popularFacetValue) => !popularFacetValue.hidden);
  }

  get sanPopularFacetValues(): TrackingLabels["san_PopularFacetValues"] | undefined {
    const facet = this.facet;
    if (facet && !facet.hidden && (!this.hidden || this.alwaysTrack)) {
      return facet.name;
    }
  }

  get isInSheet(): boolean {
    return !!this.popularFacetValuesWrapper.closest(".pl_sheet__content");
  }

  get hasStaticPopularValues(): boolean {
    return this.popularFacetValuesWrapper.classList.contains(STATIC_POPULAR_FACET_VALUES_CLASS);
  }

  public get alwaysHidden(): boolean {
    return this.popularFacetValuesWrapper.dataset.alwaysHidden === "true";
  }

  private get alwaysTrack(): boolean {
    return this.popularFacetValuesWrapper.dataset.alwaysTrack === "true";
  }

  get content(): HTMLElement | null {
    return this.popularFacetValuesWrapper.querySelector(CONTENT_SELECTOR);
  }

  updateTrackingLabels(trackingLabels: Partial<TrackingLabels>) {
    this.facetValues.forEach((value) => value.updateTrackingLabels(trackingLabels));
  }

  public addChangeTrackingLabels(trackingLabels: Partial<TrackingLabels>) {
    this.facetValues.forEach((value) => {
      value.input?.addEventListener("change", () => this.updateTrackingLabels(trackingLabels));
    });
  }

  set matching(hasMatchingValues: boolean) {
    this.popularFacetValuesWrapper.classList.toggle("heureka_popularFacetValues--matching", hasMatchingValues);
  }

  private toggleVisibility(state: boolean) {
    this.popularFacetValuesWrapper.hidden = !state;
    return this;
  }

  get parentId(): string | undefined {
    return this.popularFacetValuesWrapper.dataset?.parentId;
  }

  private facetId(): string | undefined {
    return this.facet?.name;
  }

  public initialize() {
    this.showPopularFacetValuesDuplicates();
    this.initializeMatchingValues();
    this.toggleRegularFacetValuesDuplicatesVisibility(this.mayHideRegularFacetValuesDuplicates);
    return this;
  }

  public updateGlobalTrackingLabels() {
    const filterId = this.filter?.id;
    if (filterId) {
      if (!FILTER_IDS_TO_EXCLUDE_FROM_DUPLICATES_REMOVAL.includes(filterId)) {
        this.facet?.updateFeaturesPositions();
      }
    }
    return this;
  }

  private initializeMatchingValues() {
    /*         */
    this.initializePopularFacetValues();
    const { visiblePopularFacetValuesCount, alwaysHidden, facet } = this;
    this.matching = visiblePopularFacetValuesCount > 0;
    const showPopularValues = visiblePopularFacetValuesCount > 0 && !alwaysHidden;

    this.toggleVisibility(showPopularValues);
    this.disableHiddenValues(!showPopularValues);

    if (facet) {
      /*                                                                      */
      if (!alwaysHidden) {
        facet.filter?.toggleInlineMode(showPopularValues);
      }

      this.updateSanPopularFacetValuesCount(facet, visiblePopularFacetValuesCount);
      this.trackTopFacetValues(facet);
    }
  }

  private toggleRegularFacetValuesDuplicatesVisibility(hidePopularValueDuplicates: boolean) {
    if (hidePopularValueDuplicates) {
      this.facetValues
        ?.filter((f) => {
          return f.visibleAndNotChecked;
        })
        .flatMap((f) => (f.associatedFacetValue ? [f.associatedFacetValue] : []))
        .forEach((f) => (f.hiddenPopularValueDuplicate = true));
    } else {
      this.facetValues
        .flatMap((f) => (f.associatedFacetValue ? [f.associatedFacetValue] : []))
        .forEach((f) => (f.hiddenPopularValueDuplicate = false));
    }
    this.updateVisibilityOfSecondHeadline();
  }

  private updateVisibilityOfSecondHeadline() {
    /*                                                                             */
    const shouldHide = this.facet?.unSelectedFacetValues.filter((val) => !val.hiddenPopularValueDuplicate).length === 0;
    const headline = this.facet?.unselectedValuesWrapper?.querySelector(".heureka_popularFacetValues--second-headline");
    if (headline) {
      headline.classList.toggle("heureka_popularFacetValues--headline--hidden", shouldHide);
    }
  }

  private get mayHideRegularFacetValuesDuplicates(): boolean {
    const facetId = this.facetId();
    if (facetId) {
      return !FACET_NAMES_TO_EXCLUDE_FROM_DUPLICATES_REMOVAL.includes(facetId) && !this.alwaysHidden;
    }
    return false;
  }

  public hasVisibleValues() {
    return this.visiblePopularFacetValuesCount > 0 && !this.alwaysHidden;
  }

  private showPopularFacetValuesDuplicates() {
    this.facet?.unselectedValuesWrapper?.classList.toggle("find_facet__unselectedFacetValues--showDuplicates", false);
  }

  private get visiblePopularFacetValuesCount(): number {
    return this.facet?.hidden
      ? 0
      : this.facetValues.filter((popularFacetValue) => popularFacetValue.visibleAndNotChecked).length;
  }

  get userVisiblePopularFacetValuesCount(): number {
    return this.facet?.hidden
      ? 0
      : this.facetValues.filter((popularFacetValue) => popularFacetValue.userVisible).length;
  }

  private initializePopularFacetValues() {
    this.facetValues.forEach((popularFacetValue) => popularFacetValue.initialize());
    if (this.hasStaticPopularValues) {
      this.facetValues.forEach((popularFacetValue) => popularFacetValue.updateVisibility());
    } else {
      this.updatePopularValuesVisibilities();
    }
  }

  public updatePopularValuesVisibilities() {
    if (this.isPopValuesNonStaticAndLessThan3()) {
      this.facetValues.forEach((popularFacetValue) => {
        popularFacetValue.hidden = true;
        this.popularValueDuplicateSetFalse(popularFacetValue);
      });
    } else this.updatePopularValuesVisibilitiesUntil5Matches();
  }

  public enableVisibleValues() {
    this.facetValues.forEach(
      (popularFacetValue: PopularFacetValue) => (popularFacetValue.disabled = popularFacetValue.hidden),
    );
  }

  private disableHiddenValues(hideAll: boolean) {
    this.facetValues.forEach(
      (popularFacetValue: PopularFacetValue) => (popularFacetValue.disabled = hideAll || popularFacetValue.hidden),
    );
  }

  private popularValueDuplicateSetFalse(popularFacetValue: PopularFacetValue) {
    const associatedFacetValue = popularFacetValue.associatedFacetValue;
    if (associatedFacetValue) {
      associatedFacetValue.hiddenPopularValueDuplicate = false;
    }
  }

  private isPopValuesNonStaticAndLessThan3() {
    return !this.hasStaticPopularValues && this.facetValues.filter((facetValue) => facetValue.isMatching()).length < 3;
  }

  private updatePopularValuesVisibilitiesUntil5Matches() {
    let counter = 0;
    this.facetValues.forEach((popularFacetValue) => {
      if (counter < 5 && popularFacetValue.isMatching()) {
        popularFacetValue.hidden = false;
        counter++;
      } else {
        popularFacetValue.hidden = true;
      }
    });
  }

  private updateSanPopularFacetValuesCount(facet: Facet, visiblePopularFacetValuesCount: number) {
    /*                                                                                                    */
    const facetName = facet.name;
    if (facetName !== "us-groessen") {
      const count = this.hidden && this.alwaysTrack ? this.facetValues.length : visiblePopularFacetValuesCount;
      updateLabel(facet.facet, "san_PopularFacetValueCount", count.toString());
    }
  }

  /*          */

  private trackTopFacetValues(facet: Facet) {
    const facetName = facet.name;
    if (!this.hidden || this.alwaysTrack || facetName === "us-groessen") {
      const popularFacetValues = this.visibleFacetValues.map((popularFacetValue) => popularFacetValue.input?.value);
      forEachElement(
        'input[data-ts-feature-name="Facet-Value"]',
        (input: HTMLInputElement) => {
          const facetValue = input.value;
          const matchingValue = popularFacetValues.includes(facetValue) && !input.classList.contains("pl_input__field");

          if (matchingValue) {
            updateLabel(input, "san_TopFacetValue", "true");
            input.classList.add("ts_heureka_alwaysTrack");
          } else {
            updateLabel(input, "san_TopFacetValue", "false");
          }

          if (this.alwaysTrack) {
            if (matchingValue) {
              input.dataset.tsFeatureFilterMethod = "popular";
            } else if (input.dataset.tsFeatureFilterMethod === "popular") {
              delete input.dataset.tsFeatureFilterMethod;
            }
          }
        },
        facet.facet,
      );
    }
    return this;
  }

  private declareOverflowBoxContainer() {
    this.popularFacetValuesWrapper.classList.add("ts_heureka_overflowBoxContainer");
    OverflowBoxContainer.declareOverflowBoxContainer(this.popularFacetValuesWrapper)?.selectAndSetContent(
      ".find_facet__content--popularValues",
    );
  }

  private removeOverflowBoxContainer() {
    OverflowBoxContainer.declareOverflowBoxContainer(this.popularFacetValuesWrapper)?.remove();
  }

  public toggleChipsContainer(isChipsContainer: boolean, useWhiteBackgroundColor = false) {
    if (isChipsContainer) {
      this.declareOverflowBoxContainer();
    } else {
      this.removeOverflowBoxContainer();
    }
    this.facetValues.forEach((facetValue) => {
      if (isChipsContainer) {
        facetValue.transformToChip(useWhiteBackgroundColor);
      } else {
        facetValue.transformToCheckbox();
      }
    });
  }

  public allFacetValuesAreHidden(): boolean {
    return !this.facetValues.find((facetValue) => !facetValue.popularFacetValue.hidden);
  }

  public clone() {
    return PopularFacetValuesWrapper.factory.create(clone(this.popularFacetValuesWrapper));
  }
}

function onFilterSectionLoaded() {
  const popularFacetValuesWrappers: PopularFacetValuesWrapper[] = PopularFacetValuesWrapper.factory.all();
  const popularFacetIds = popularFacetValuesWrappers
    .map((wrapper) => wrapper.updateGlobalTrackingLabels())
    .map((wrapper) => wrapper.sanPopularFacetValues)
    .filter((facetId) => !!facetId);

  const rangeChipFacetIds = Facet.ranges()
    /*                                            */
    .filter((facet) => facet.allFacetValues.length > 0)
    .map((facet) => facet.name);

  const joinedFacetIds = [...popularFacetIds, ...rangeChipFacetIds].sort().join("|");

  const placeholder = getElementById(PLACEHOLDER_ID);
  if (placeholder && joinedFacetIds) {
    updateLegacyLabel(placeholder, "san_PopularFacetValues", joinedFacetIds);
  }

  Filter.factory.forEach((filter) => {
    filter.sanPopularFacetValues = joinedFacetIds;
  });

  popularFacetValuesTracking();
}

function onFiltersLoaded() {
  PopularFacetValuesWrapper.factory
    .all()
    .filter((wrapper) => !wrapper.hasStaticPopularValues)
    .forEach((wrapper) => wrapper.initialize());
}

function initializeStaticPopularValues() {
  PopularFacetValuesWrapper.factory
    .all()
    .filter((wrapper) => wrapper.hasStaticPopularValues)
    .forEach((wrapper) => wrapper.initialize());
}

function createPlaceholder() {
  /*                                                            */
  let placeholderElement = getElementById(PLACEHOLDER_ID);
  if (!placeholderElement) {
    placeholderElement = document.createElement("div");
    placeholderElement.id = PLACEHOLDER_ID;
    placeholderElement.hidden = true;
    updateLegacyLabel(placeholderElement, "san_PopularFacetValues", "none");
    document.body.appendChild(placeholderElement);
  }
}

/*          */

export function readSanPopularFacetValues(): TrackingLabels["san_PopularFacetValues"] {
  const placeholder = getElementById(PLACEHOLDER_ID);
  return (placeholder && readLegacyLabel(placeholder, "san_PopularFacetValues")) || "none";
}

export function updateSanPopularFacetValues(
  element: HTMLElement | null,
  san_PopularFacetValues: TrackingLabels["san_PopularFacetValues"],
) {
  if (element) {
    updateLegacyLabel(element, "san_PopularFacetValues", san_PopularFacetValues);
  }
}

function popularFacetValuesTracking() {
  const san_PopularFacetValues = readSanPopularFacetValues();
  tracker.addToPageImpression({
    san_PopularFacetValues: san_PopularFacetValues,
  });
  updateSanPopularFacetValues(filterSheetButton(), san_PopularFacetValues);
  updateSanPopularFacetValues(quickFilterButton(), san_PopularFacetValues);
  updateSanPopularFacetValues(menuBarButtonForm(), san_PopularFacetValues);
  forEachElement(FACET_VALUE_TAG_FORM_SELECTOR, (elem) => updateSanPopularFacetValues(elem, san_PopularFacetValues));
}

export function registerPopularFacetValuesListeners() {
  eventQBus.once("heureka.filters.loaded", createPlaceholder);
  eventQBus.once("heureka.filters.loaded", onFiltersLoaded);
  eventQBus.on("heureka.filters.loaded", initializeStaticPopularValues);
  eventQBus.on("heureka.filterSection.loaded", onFilterSectionLoaded);
}
