import { mapElements } from "../../util/Utils";
import { eventQBus } from "../../types/EventQBus";
import { copyLabels } from "../../tracking/FeatureTracking";
import { HeurekaElementFactory } from "../../util/HeurekaElementFactory";
import { UpdateReferencedAction } from "../UpdateReferencedAction";

const SELECT_ALL_BUTTON_CLASS = "ts_heureka_selectAll";

const UNCHECKED_VISIBLE_VALUES = "li:not([hidden]) input[type=checkbox].js_tValue:not(:checked)";
const CHECKED_VISIBLE_CHECKBOXES = "li:not([hidden]) input[type=checkbox].js_tValue:checked";
const CHECKED_VISIBLE_RADIOS = "li:not([hidden]) input[type=radio].js_tValue:checked";
const ACTIVE_RANGES = ".ts_heureka_slider input[type=text].js_tValue:not([value='_,_'])";
const ACTIVE_VISIBLE_VALUES = `${CHECKED_VISIBLE_CHECKBOXES},${CHECKED_VISIBLE_RADIOS},${ACTIVE_RANGES}`;

export class SelectAllButton {
  static readonly factory = HeurekaElementFactory.byElement("button", SELECT_ALL_BUTTON_CLASS, SelectAllButton);

  /*               */

  constructor(
    readonly button: HTMLButtonElement,
    readonly deselectMode = SelectAllButton.#deselectMode(button.dataset.selectMode),
  ) {
    this.button = button;
    this.deselectMode = deselectMode;
  }

  static #deselectMode(mode?: string) {
    return mode === "deselect";
  }

  /*               */

  static initAll(rootElement?: ParentNode) {
    SelectAllButton.factory.forEach(SelectAllButton.#init, rootElement);
  }

  static #init(elem: SelectAllButton) {
    elem.#formChanged();
    elem.#registerOnFormChange();
    elem.#registerOnClick();
  }

  #registerOnFormChange() {
    const { form } = this.button;
    if (form) {
      const listener = this.#formChanged.bind(this);
      const options = { passive: true };
      form.addEventListener("change", listener, options);
      form.addEventListener("input", listener, options);
      form.addEventListener("reset", listener, options);
    }
    return this;
  }

  #registerOnClick() {
    this.button.addEventListener("click", this.#clicked.bind(this));
    return this;
  }

  /*                       */

  show() {
    this.button.hidden = false;
  }

  hide() {
    this.button.hidden = true;
  }

  toggleVisibility(force = !this.button.hidden) {
    this.button.hidden = force;
    return this;
  }

  toggleInputs() {
    return this.deselectMode ? this.#deactivateActiveValues() : this.#checkUncheckedValues();
  }

  isSelectAll() {
    return !this.deselectMode;
  }

  /*                 */

  #formChanged() {
    /*          */
    this.toggleVisibility(this.deselectMode != this.#hasAnyVisibleActiveValue());
  }

  #clicked(event: Event) {
    const changedItems = this.toggleInputs().map(copyLabels.bind(this, this.button)).length;
    if (changedItems) {
      this.#triggerChange();
    }
    event.stopPropagation();
  }

  /*       */

  /*                                                     */

  #hasAnyVisibleActiveValue(form: HTMLFormElement | null = this.button.form) {
    return !!form?.querySelector(ACTIVE_VISIBLE_VALUES);
  }

  #checkUncheckedValues(form: HTMLFormElement | null = this.button.form) {
    return mapElements<HTMLInputElement, HTMLInputElement>(
      UNCHECKED_VISIBLE_VALUES,
      (elem) => {
        elem.checked = true;
        UpdateReferencedAction.updateAllValues(elem);
        return elem;
      },
      form || undefined,
    );
  }

  #deactivateActiveValues(form: HTMLFormElement | null = this.button.form) {
    return mapElements<HTMLInputElement, HTMLInputElement>(
      ACTIVE_VISIBLE_VALUES,
      SelectAllButton.clearInput,
      form || undefined,
    );
  }

  #triggerChange(form: HTMLFormElement | null = this.button.form) {
    form?.dispatchEvent(new Event("change", { bubbles: true }));
  }

  /*                                                      */

  private static clearInput(elem: HTMLInputElement) {
    const type = elem.type ? elem.type : elem.getAttribute("type");
    switch (type) {
      case "text":
        elem.value = "_,_";
        break;
      case "radio":
      case "checkbox":
        elem.checked = false;
        UpdateReferencedAction.updateAllValues(elem);
        break;
    }
    return elem;
  }
}

function onFiltersLoaded() {
  SelectAllButton.initAll();
}

export function registerFilterFormSelectAllButtons() {
  eventQBus.on("heureka.filters.loaded", onFiltersLoaded);
}
