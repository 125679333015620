export interface Scale {
  toValue(x: number): number;
  fromValue(x: number): number;
}

export const linScale: Scale = {
  toValue: (x) => x,
  fromValue: (y) => y,
};

export const logScale: Scale = {
  toValue: (x) => Math.log(1 + x), /*         */
  fromValue: (y) => Math.exp(y) - 1,
};

export default function scale(logarithmic: boolean) {
  return logarithmic ? logScale : linScale;
}
