import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import type { FacetActivity } from "../multifiltering/FacetActivity";
import { FacetGroup } from "./FacetGroup";
import { Filter } from "./Filter";
import { updateStatus } from "../tracking/FeatureTracking";
import type { FeatureStatus } from "../tracking/Tracking";
import { PopularFacetValuesWrapper } from "./PopularFacetValues";
import type { FacetVisibilityAware } from "./FacetVisibilityAware";
import { element, move, notEmpty } from "../util/Utils";
import { FacetValue } from "./FacetValue";
import type { FacetExperiment } from "./FacetExperiment";
import { getActiveVariation } from "../experiment/Experiments";
import { isActive } from "../toggle/Toggle";

const FACET_CLASS = "find_facet";
const FACET_CONTENT_CLASS = "find_facet__content";
const FACET_CONTENT_SELECTOR = `.${FACET_CONTENT_CLASS}`;
const FACET_CONTENT_ALL_VALUES_CLASS = `${FACET_CONTENT_CLASS}--all`;
const FACET_CONTENT_ALL_VALUES_SELECTOR = `.${FACET_CONTENT_ALL_VALUES_CLASS}`;
const UNSELECTED_FACET_VALUES_CLASS = `find_facet__unselectedFacetValues`;
const UNSELECTED_FACET_VALUES_SELECTOR = `.${UNSELECTED_FACET_VALUES_CLASS}`;
const UNSELECTED_FACET_CONTENT_SELECTOR = `.${UNSELECTED_FACET_VALUES_CLASS} .${FACET_CONTENT_CLASS}`;
const FACET_CONTENT_POPULAR_VALUES_CLASS = "find_facet__content--popularValues";
const SELECTED_FACET_CONTENT_VALUES_CLASS = "find_facet__content--selected";
const NON_POPULAR_FACET_CONTENT_SELECTOR = `.${UNSELECTED_FACET_VALUES_CLASS} .${FACET_CONTENT_CLASS}:not(.${FACET_CONTENT_POPULAR_VALUES_CLASS})`;
const EMPTY_FACET_CLASS = `${FACET_CLASS}--empty`;
const FACET_RANGES_CLASS = `${FACET_CLASS}--range`;
/*                                                                                            */
const UNSELECTED_FACET_CONTENT_SELECTOR_DEPRECATED = `.${FACET_CONTENT_CLASS}:not(.${FACET_CONTENT_POPULAR_VALUES_CLASS}):not(.${SELECTED_FACET_CONTENT_VALUES_CLASS})`;
const SELECTED_FACET_CONTENT_SELECTOR = `.${SELECTED_FACET_CONTENT_VALUES_CLASS}`;

export class Facet implements FacetVisibilityAware, FacetExperiment {
  static readonly factory = HeurekaElementFactory.byElement("fieldset", FACET_CLASS, Facet);

  constructor(readonly facet: HTMLFieldSetElement) {}

  /*                  */

  static facetId(id: string, root?: NonElementParentNode): Facet | undefined {
    return Facet.factory.byId(`facet_${id}`, root);
  }

  static facetName(facetName: string, root?: ParentNode): Facet | undefined {
    return Facet.factory.pick(`fieldset.find_facet[data-facet-name="${facetName}"]`, root);
  }

  static ranges(root?: ParentNode): Facet[] {
    return Facet.factory.pickAll(`${Facet.factory.selector}.${FACET_RANGES_CLASS}`, root);
  }

  /*               */
  static prepareAll(fragment: DocumentFragment) {
    Facet.factory.forEach((facet: Facet) => facet.prepare(), fragment);
  }

  private prepare() {
    const oldFacet = Facet.facetId(this.id);
    if (oldFacet) {
      const oldPopularFacetValuesWrapper = oldFacet.popularFacetValuesWrapper;
      /*                                                                                           */
      if (oldPopularFacetValuesWrapper && !oldPopularFacetValuesWrapper.hasStaticPopularValues) {
        this.popularFacetValuesWrapper = oldPopularFacetValuesWrapper;
      }

      if (getActiveVariation("e3213", "StatusQuo") === "StatusQuo") {
        /*                                                        */
        this.updateVisibility(oldFacet.hidden);
      }
      const facetName = this.name;
      if (facetName === "kategorien") {
        /*                                                                 */
        if (history.state?.categories) {
          this.reInitializeFacetValuesFrom(history.state.categories);
        } else {
          const categories: string[] = [];
          oldFacet.allFacetValues
            .map((oldFacetValue) =>
              oldFacetValue.checked ? FacetValue.fromId(oldFacetValue.id, this.facet) : undefined,
            )
            .filter(notEmpty)
            .forEach((newFacetValue) => {
              newFacetValue.toggleChecked(true);
              if (newFacetValue.value) {
                categories.push(newFacetValue.value);
              }
              if (isActive("HEUREKA_1367_REFACTOR_FACET_VALUE_TAGS")) {
                move(newFacetValue.li, this.facet.querySelector(SELECTED_FACET_CONTENT_SELECTOR));
              }
            });
          history.replaceState({ ...history.state, categories: categories }, "");
        }
      }
    } else if (this.name === "kategorien" && history.state?.categories) {
      this.reInitializeFacetValuesFrom(history.state.categories);
    }
  }

  public reInitializeFacetValuesFrom(facetValueNames: string[]) {
    this.allFacetValues.forEach((facetValue) => {
      if (facetValue.checked && facetValue.value && !facetValueNames.includes(facetValue.value)) {
        /*                                                        */
        facetValue.pseudoDeselect();
      }
      if (!facetValue.checked && facetValue.value && facetValueNames.includes(facetValue.value)) {
        /*                                                  */
        facetValue.toggleChecked(true);
      }
    });
  }

  /*                       */

  get onexId(): string | undefined {
    return this.facet.dataset.onexid;
  }

  get onexGroup(): string | undefined {
    return this.facet.dataset.onexgroup;
  }

  get id(): string {
    return this.facet.id.substring("facet_".length);
  }

  get name(): string | undefined {
    return this.facet.dataset?.facetName;
  }

  get facetGroup() {
    return FacetGroup.factory.closest(this.facet);
  }

  get filter(): Filter | undefined {
    return Filter.factory.declare(this.facet.form);
  }

  private get facetVisibility() {
    return this.facet.dataset.facetVisibility;
  }

  get ignoreMinimalCoverage(): boolean {
    return this.facet?.dataset.heurekaIgnoreMinimalCoverage === "true";
  }

  get mayHide(): boolean {
    return this.facetVisibility !== "visible";
  }

  get mayShow(): boolean {
    return this.facetVisibility !== "hidden";
  }

  get selected(): boolean {
    return this.facet.classList.contains("find_facet--active");
  }

  get visible(): boolean {
    return !this.hidden && this.filter?.hidden === false;
  }

  private get nextBestActionCandidate(): boolean {
    return this.facet.dataset.nextBestActionCandidate === "true";
  }

  get mayNextBestAction(): boolean {
    if (this.nextBestActionCandidate && !this.hidden) {
      const visiblePopularValues = this.popularFacetValuesWrapper?.visibleFacetValues?.length || 0;
      return visiblePopularValues > 0;
    }
    return false;
  }

  get hidden(): boolean {
    return this.facet.hidden;
  }

  set hidden(hidden) {
    this.facet.hidden = hidden;
    if (hidden) {
      this.featureStatus = this.hiddenStatus();
    }
  }

  get isFullyInlinedMultiselect(): boolean {
    return !!this.getInliningPlaceholder();
  }

  set featureStatus(status: FeatureStatus | undefined) {
    updateStatus(this.facet, status);
  }

  hiddenStatus(): FeatureStatus | undefined {
    const facetName = this.name;
    return facetName === "kategorie" || facetName === "kategorien" ? "hidden" : undefined;
  }

  get popularFacetValuesWrapper(): PopularFacetValuesWrapper | undefined {
    return PopularFacetValuesWrapper.factory.pick(undefined, this.facet);
  }

  set popularFacetValuesWrapper(wrapper: PopularFacetValuesWrapper | undefined) {
    if (wrapper) {
      const parent = element(UNSELECTED_FACET_VALUES_SELECTOR, this.facet);
      if (parent) {
        parent.insertBefore(wrapper.popularFacetValuesWrapper, parent.firstChild);
        wrapper.initialize();
      }
    }
  }

  public get selectedFacetValues(): FacetValue[] {
    const selectedFacetValuesContent = this.selectedFacetValuesContent();
    return (selectedFacetValuesContent && FacetValue.factory.all(selectedFacetValuesContent)) || [];
  }

  private selectedFacetValuesContent(): HTMLUListElement | null {
    return this.facet.querySelector(SELECTED_FACET_CONTENT_SELECTOR);
  }

  public get visibleUnSelectedFacetValues(): FacetValue[] {
    const unselectedFacetValuesContent = this.facet.querySelector(UNSELECTED_FACET_CONTENT_SELECTOR_DEPRECATED);
    return (
      (unselectedFacetValuesContent &&
        FacetValue.factory
          .all(unselectedFacetValuesContent)
          .filter((facetValue) => !facetValue.hiddenPopularValueDuplicate)) ||
      []
    );
  }

  public get unSelectedFacetValues(): FacetValue[] {
    const unselectedFacetValuesContent = this.facet.querySelector(UNSELECTED_FACET_CONTENT_SELECTOR_DEPRECATED);
    return (unselectedFacetValuesContent && FacetValue.factory.all(unselectedFacetValuesContent)) || [];
  }

  /**
 *
 *
 *
 */
  public get allFacetValuesDeprecated(): FacetValue[] {
    const facetValuesContent = this.facet.querySelector(FACET_CONTENT_SELECTOR);
    return (facetValuesContent && FacetValue.factory.all(facetValuesContent)) || [];
  }

  public get allFacetValues(): FacetValue[] {
    return FacetValue.factory.all(this.facet);
  }

  private getInliningPlaceholder() {
    return this.facet.querySelector<HTMLUListElement>(FACET_CONTENT_ALL_VALUES_SELECTOR);
  }

  public inline() {
    const inliningPlaceholder = this.getInliningPlaceholder();
    this.allFacetValues.forEach((facetValue) => {
      move(facetValue.liElem, inliningPlaceholder);
    });
    this.allFacetValues.forEach((facetValue) => facetValue.transformToChip());
  }

  public removeInlining() {
    FacetValue.factory.all(this.getInliningPlaceholder()).forEach((facetValue) => {
      if (facetValue.checked) {
        move(facetValue.liElem, this.facet.querySelector<HTMLUListElement>(SELECTED_FACET_CONTENT_SELECTOR));
      } else {
        move(facetValue.liElem, this.facet.querySelector<HTMLUListElement>(UNSELECTED_FACET_CONTENT_SELECTOR));
      }
    });
    this.allFacetValues.forEach((facetValue) => facetValue.transformToCheckbox());
  }

  updateVisibility(shouldHide: boolean) {
    this.hidden = shouldHide ? this.mayHide : !this.mayShow;
    this.facetGroup?.updateVisibility(this.hidden);
    this.filter?.updateVisibility(this.hidden);
  }

  get facetContent() {
    return this.facet.querySelector<HTMLElement>(NON_POPULAR_FACET_CONTENT_SELECTOR);
  }

  get empty(): boolean {
    return this.facet.classList.contains(EMPTY_FACET_CLASS);
  }

  get unselectedValuesWrapper(): HTMLElement | null {
    return this.facet.querySelector<HTMLElement>(UNSELECTED_FACET_VALUES_SELECTOR) || null;
  }

  toggleCountsVisibility(visible: boolean) {
    this.facet.classList.toggle("heureka_hideCount", !visible);
  }

  updateFeaturesPositions() {
    const visiblePopularFacetValues = this.popularFacetValuesWrapper?.visibleAndNotCheckedFacetValues;
    if (visiblePopularFacetValues && visiblePopularFacetValues.length > 0 && this.popularFacetValuesWrapper) {
      this.allFacetValues.map((facetValue) => facetValue.clearPosition());
      const selectedFacetValues = this.selectedFacetValues;
      selectedFacetValues.forEach((facetValue, index) => {
        facetValue.position = index + 1;
      });

      const popularValuesOffset = this.popularFacetValuesWrapper?.hidden ? 0 : selectedFacetValues.length;
      visiblePopularFacetValues.forEach((popularFacetValue, index) => {
        popularFacetValue.position = index + 1 + popularValuesOffset;
      });

      const unselectedValuesOffset =
        (this.popularFacetValuesWrapper?.hidden ? 0 : visiblePopularFacetValues.length) + selectedFacetValues.length;
      this.visibleUnSelectedFacetValues?.forEach((facetValue, index) => {
        facetValue.position = index + 1 + unselectedValuesOffset;
      });
    }
  }

  get activity(): FacetActivity {
    if (this.hidden) {
      return "hidden";
    }
    if (this.selected) {
      return "active";
    }
    return "view";
  }

  /*                                                                                                 */
  getFacetValue(value: string): FacetValue | undefined {
    const facetValueSelector = `li:not(.heureka_popularFacetValues_value) input[value="${value}"]`;
    return FacetValue.factory.declare(this.facet.querySelector(facetValueSelector)?.closest("label"));
  }
}
