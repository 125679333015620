import { eventQBus } from "../types/EventQBus";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { FilterAccordions } from "./FilterAccordions";
import { FilterTitles } from "./FilterTitles";
import { FilterRelevancy } from "./FilterRelevancy";
import { Filter } from "./Filter";
import type { FilterAccordion } from "./FilterAccordion";
import type { FilterTitle } from "./FilterTitle";
import { SortableFilter } from "./SortableFilter";

const ID_SORTED_FILTERS_BY_QUERY = "heureka_initial_sortByQuery";

export class FilterSorting {
  private static readonly factory = HeurekaElementFactory.byId(ID_SORTED_FILTERS_BY_QUERY, FilterSorting);
  private static sortedFilterList: SortableFilter[] = [];

  /*               */
  constructor(private readonly elem: HTMLElement) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return FilterSorting.factory.pick(undefined, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", FilterSorting.initAll);
  }

  static initAll(event?: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FilterSorting.template(rootElement)?.init();
  }

  protected init() {
    if (
      (!FilterSorting.sortedFilterList || FilterSorting.sortedFilterList.length === 0) &&
      FilterRelevancy.template()?.knownFilterIds.length !== 0 &&
      FilterAccordions.template()?.values.length !== 0
    ) {
      FilterSorting.sortedFilterList = FilterSorting.template()
        ?.sortedFilters(FilterAccordions.template()!.values) /*                                                       */
        .filter((f) => !!f) as SortableFilter[];
    }
    FilterAccordions.template()?.sortValues(this);
    FilterTitles.template()?.sortValues(this);
    return this;
  }

  /*                       */

  /*                                                                                                 */
  denseModeVisibilityComparator = (a: SortableFilter, b: SortableFilter) => {
    const aVisibility = a.isVisibleInDenseMode ? 1 : 0;
    const bVisibility = b.isVisibleInDenseMode ? 1 : 0;
    return bVisibility - aVisibility;
  };

  /*                              */
  filterRelevancyComparator = (a: SortableFilter, b: SortableFilter) => {
    const aVisibility = a.isRelevant ? 2 : a.isIrrelevant ? 1 : 0;
    const bVisibility = b.isRelevant ? 2 : b.isIrrelevant ? 1 : 0;
    return (bVisibility - aVisibility) / 2; /*                            */
  };

  alphaNumComparator = (a: SortableFilter, b: SortableFilter) => {
    const minLength = Math.min(a.filterId.length, b.filterId.length);

    for (let i = 0; i < minLength; i++) {
      const diff = a.filterId.charCodeAt(i) - b.filterId.charCodeAt(i);
      if (diff !== 0) return diff;
    }

    /*                                                            */
    return a.filterId.length - b.filterId.length;
  };

  private sortedFilters(filters: HTMLElement[]): SortableFilter[] {
    let sortedFilters: SortableFilter[] = filters
      .map((f) => {
        const filter = Filter.filterId(f.dataset.filterId!);
        if (filter) {
          return new SortableFilter(
            filter.id,
            filter.isDenseModeFilter,
            filter.isRelevant,
            filter.isIrrelevant,
            filter.forceIndex,
          );
        }
      })
      .filter((f) => !!f) as SortableFilter[];

    const knownFilterIds = FilterRelevancy.template()?.knownFilterIds || [];

    function relevancyScoreComparator(knownFilterIds: string[]): Comparator<SortableFilter> {
      return (a: SortableFilter, b: SortableFilter) => {
        return knownFilterIds.indexOf(a.filterId) - knownFilterIds.indexOf(b.filterId);
      };
    }

    /*           */
    sortedFilters = sortedFilters.sort(
      chainedComparator(
        this.denseModeVisibilityComparator,
        this.filterRelevancyComparator,
        relevancyScoreComparator(knownFilterIds),
        this.alphaNumComparator,
      ),
    );

    sortedFilters = FilterSorting.rearrangeForcedFilters(sortedFilters);

    /*                      */
    const localNav = sortedFilters.find((f) => f.filterId === "localNavigation");
    if (localNav) {
      sortedFilters.unshift(localNav);
    }

    return sortedFilters;
  }

  /*                                                                            */
  public sortFiltersByQuery(filters: HTMLElement[]) {
    const sortedFilterIds = FilterSorting.sortedFilterList.map((f) => f.filterId);
    return filters
      .filter((f) => !!f.dataset.filterId)
      .sort((a, b) => {
        return sortedFilterIds.indexOf(a.dataset.filterId!) - sortedFilterIds.indexOf(b.dataset.filterId!);
      });
  }

  public sortFilterTitlesByQuery(filterTitles: FilterTitle[]) {
    const sortedFilterIds = FilterSorting.sortedFilterList
      .filter((f) => f.isVisibleInDenseMode || f.isIrrelevant)
      .map((f) => f.filterId);
    return filterTitles
      .filter((f) => !!f.filterId && sortedFilterIds.indexOf(f.filterId!) >= 0)
      .sort((a, b) => {
        return sortedFilterIds.indexOf(a.filterId!) - sortedFilterIds.indexOf(b.filterId!);
      });
  }

  public sortAccordionsByQuery(filterAccordions: FilterAccordion[]) {
    const sortedFilterIds = FilterSorting.sortedFilterList
      .filter((f) => f.isVisibleInDenseMode || f.isIrrelevant)
      .map((f) => f.filterId);
    return filterAccordions
      .filter((f) => !!f.filterId && sortedFilterIds.indexOf(f.filterId!) >= 0)
      .sort((a, b) => {
        return sortedFilterIds.indexOf(a.filterId!) - sortedFilterIds.indexOf(b.filterId!);
      });
  }

  private static rearrangeForcedFilters(filters: SortableFilter[]): SortableFilter[] {
    const filtersWithForcedIndex = filters.filter((f) => f.forceIndex != undefined && f.forceIndex > -1);

    filtersWithForcedIndex.forEach((f) => FilterSorting.move(f, filters, f.forceIndex!));
    return filters;
  }

  private static move(sortableFilter: SortableFilter, filters: SortableFilter[], toIndex: number) {
    filters.splice(filters.indexOf(sortableFilter), 1);
    filters.splice(toIndex, 0, sortableFilter);
  }
}

type Comparator<T> = (a: T, b: T) => number; /*         */

/**
 *
 */
function chainedComparator<T>(...comparators: Comparator<T>[]): Comparator<T> {
  return (a: T, b: T): number => {
    for (const comparator of comparators) {
      const result = comparator(a, b);
      if (result !== 0) return result;
    }
    return 0; /*                                      */
  };
}
