<script lang="ts">
  import type { FacetValue, Interval } from "../FacetValue";
  import { addTrackingAttributes } from "../Tracking";
  import inputDefault from "../InputDefaultAction.ts";
  import type { FeatureTracking } from "../FeatureTracking";
  import type { FeatureTrackingReference } from "../FeatureTrackingReference";
  import MinMaxInput from "./MinMaxInput.svelte";
  import SliderInput from "./SliderInput.svelte";

  interface Props {
    elementId: string;
    facetName: string;
    selectedValues?: string;
    activeValue: FacetValue;
    featureTracking?: FeatureTracking;
    availableRange: Required<Interval>;
    unit?: string;
    logarithmic?: boolean;
    useMinMax?: boolean;
    useSlider?: boolean;
    natural?: boolean;
  }

  let {
    elementId,
    facetName,
    selectedValues = $bindable(),
    activeValue,
    featureTracking = undefined,
    availableRange,
    unit,
    logarithmic = false,
    useMinMax = true,
    useSlider = false,
    natural = false,
  }: Props = $props();

  let inputElement: HTMLInputElement | undefined = $state();
  let currentValue = $state(activeValue);

  function onInput() {
    selectedValues = currentValue.value;
    if (inputElement) {
      inputElement.checked = true;
      /*                                                                                                                 */
      /*                                                                                     */
      inputElement.defaultChecked = false;
    }
  }

  /*                     */
  if (featureTracking) {
    if (!activeValue.selected) {
      delete featureTracking.status;
    }
  }

  const featureTrackingReference: FeatureTrackingReference = {
    ref: elementId,
    labels: {},
  };
</script>

<div class="hc_customRange" data-facet-name={facetName} oninput={onInput}>
  <label hidden>
    <input
      id={elementId}
      bind:this={inputElement}
      checked={activeValue.selected}
      class="js_tValue hc_customRange__input"
      name={facetName}
      readonly
      type="radio"
      value={currentValue.value}
      use:addTrackingAttributes={featureTracking}
      use:inputDefault
    />
  </label>
  {#if useMinMax}
    <MinMaxInput {unit} bind:activeValue={currentValue} {availableRange} {featureTrackingReference} />
  {/if}
  {#if useSlider}
    <SliderInput bind:activeValue={currentValue} {availableRange} {logarithmic} {natural} {featureTrackingReference} />
  {/if}
</div>

<style lang="scss">
  .hc_customRange {
    display: flex;
    flex-direction: column;
    gap: calc(var(--oc-semantic-spacing-150) - var(--oc-semantic-spacing-25));
  }
</style>
