import { eventQBus } from "../types/EventQBus";

/**
 *
 */
export class ChangeCounter implements EventListenerObject {
  /**
 *
 *
 *
 */
  private constructor() {}
  /*               */

  static register() {
    eventQBus.once("heureka.filters.loaded", ChangeCounter.init);
  }

  static init() {
    document.addEventListener("change", new ChangeCounter(), { capture: true, passive: true });
  }

  /*                 */

  handleEvent(event: Event) {
    const target = event.target as HTMLElement;
    /*                                           */
    if (target instanceof HTMLInputElement) {
      const counter: number = parseInt(target.dataset.changeCounter || "0");
      target.dataset.changeCounter = (counter + 1).toString();
    }
  }
}
