<script lang="ts">
  interface Props {
    title: string;
    itemCount?: number;
    size?: "50" | "75" | "100" | "125";
    bold?: boolean;
  }

  let { title, itemCount, size = "100", bold }: Props = $props();
</script>

<span class="hc_label" data-count={itemCount}
  ><span class="hc_label__title oc-copy-{size}" class:oc-copy--bold={bold}>{title}</span></span
>

<style lang="scss">
  .hc_label {
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    gap: var(--oc-semantic-spacing-50);
    overflow: hidden;

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 0 1 min-content;
    }

    &[data-count]::after {
      content: "(" attr(data-count) ")";
      flex: 0 0 max-content;
      color: var(--oc-semantic-color-text-secondary);
    }
  }
</style>
