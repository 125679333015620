import type { Appendable } from "../util/Appendable";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { clone, forEachElement, getElementById } from "../util/Utils";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { fireFilterSubmit } from "../filter/FilterFormActions";
import { clearStatus, updateLabel } from "../tracking/FeatureTracking";
import tracker from "../tracking/Tracker";

const TEMPLATE_ID = `heureka_filterBannerTemplate`;
const SELECTOR_TEMPLATE = `#${TEMPLATE_ID}`;
const ID_WRAPPER = `heureka_filterBanner`;
const CLASS_BANNER = `heureka_filterBanner`;
const CLASS_SWITCH = `heureka_filterBanner__switch`;
const SELECTOR_SWITCH = `.${CLASS_SWITCH}`;
const SELECTOR_BANNER = `.${CLASS_BANNER}`;
const SELECTOR_TEMPLATE_BANNER = `${SELECTOR_TEMPLATE} ${SELECTOR_BANNER}`;
const SESSION_STORAGE_KEY = "heureka_ottoSwitchBannerDisabled";

export default class FilterSwitchBanner implements Appendable {
  static readonly factory = HeurekaElementFactory.byClass(CLASS_BANNER, FilterSwitchBanner);

  /*               */

  constructor(
    readonly banner: HTMLElement,
    readonly wrapper: HTMLElement | null = document.getElementById(ID_WRAPPER),
    readonly bannerSwitch: HTMLInputElement | null = banner.querySelector(SELECTOR_SWITCH),
    readonly originFacetValue: HTMLInputElement | undefined = this.originFilter(banner)?.querySelector(
      SELECTOR_SWITCH,
    ) || undefined,
  ) {}

  private originFilter(banner: HTMLElement): HTMLFormElement | undefined {
    const bannerFilterId = banner?.dataset.bannerFilter;
    return bannerFilterId ? getElementById<HTMLFormElement>(`find_filter_${bannerFilterId}`) : undefined;
  }

  /*                  */

  static fromTemplate(rootElement?: ParentNode | null): FilterSwitchBanner | undefined {
    return FilterSwitchBanner.factory.pick(SELECTOR_TEMPLATE_BANNER, rootElement);
  }

  /*               */

  private init() {
    if (this.originFacetValue) {
      const originBannerFilterForm = this.originFacetValue.form;
      if (originBannerFilterForm && !originBannerFilterForm.hidden) {
        /*                   */
        document.querySelector(".find_refinementBar")?.classList.add("find_refinementBar--banner");
        this.setChecked(this.originFacetValue.checked);
        this.bannerSwitch?.addEventListener("change", (event) => {
          const target = event.target as HTMLInputElement;
          if (target) {
            this.updateOriginValue(target.checked);
            fireFilterSubmit(originBannerFilterForm);
          }
        });
        this.banner.addEventListener("oc-close", () => this.onCloseClick());
        this.visible = !sessionStorage.getItem(SESSION_STORAGE_KEY);
      } else {
        this.visible = false; /*                   */
      }
    }

    return this;
  }

  private updateOriginValue(checked: boolean) {
    if (this.originFacetValue) {
      this.originFacetValue.checked = checked;
      updateLabel(this.originFacetValue, "san_FilterMethod", "switch");
    }
  }

  private onCloseClick() {
    this.visible = false;
    sessionStorage.setItem(SESSION_STORAGE_KEY, "true");
    this.activityTracking("close_banner");
  }

  /*                       */

  appendTo(elem: HTMLElement): void {
    elem.appendChild(this.banner);
  }

  clone() {
    const bannerClone = clone(this.banner);
    this.removeTrackingFrom(bannerClone);
    return FilterSwitchBanner.factory.create(bannerClone).init();
  }

  private removeTrackingFrom(bannerClone: HTMLElement) {
    forEachElement(`[data-ts-feature-status]`, clearStatus, bannerClone);
  }

  get visible(): boolean {
    return !this.banner.hidden && this.banner.childElementCount > 0;
  }

  set visible(visibility: boolean) {
    this.banner.hidden = !visibility;
    if (this.wrapper) {
      this.wrapper.hidden = !visibility;
    }
    if (!visibility) {
      const origBanner = FilterSwitchBanner.fromTemplate()?.banner;
      if (origBanner) {
        this.removeTrackingFrom(origBanner);
      }
    }
  }

  get checked(): boolean {
    return this.bannerSwitch?.checked || false;
  }

  setChecked(status: boolean) {
    if (this.bannerSwitch) {
      this.bannerSwitch.checked = status;
    }
  }

  /*       */

  private activityTracking(activity: TrackingLabels["san_FacetActivity"]) {
    tracker.submitEvent({
      san_FacetActivity: activity,
      san_FacetActivityType: this.originFacetValue?.name,
    });
  }
}
