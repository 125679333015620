<script lang="ts">
  import { onMount } from "svelte";
  import { SliderHost } from "./SliderHost.ts";
  import type { SliderTypes } from "./SliderTypes.ts";
  import { registerListeners } from "./init.ts";
  import { SliderElement } from "./SliderElement.ts";
  import SliderIcon from "./SliderIcon.svelte";
  import SliderInputFieldText from "./SliderInputFieldText.svelte";
  import { nanoid } from "@otto-ec/global-resources/misc";

  interface Props {
    id?: string;
    wrapperId: string;
    minLabel: string;
    maxLabel: string;
    minValue: string;
    maxValue: string;
    facetName: string;
    initialMinValue: string;
    initialMaxValue: string;
    availablePreselects: string;
    logarithmic?: string;
    maxPos?: string;
    delayedActionMillis: string;
    showDefaultPlaceholders?: string;
    focusFloatsBothLabels?: string;
    hideSlider?: string;
    inputPrefixIcon?: string;
    inputPrefixText?: string;
    inputSuffixIcon?: string;
    inputSuffixText?: string;
  }

  let {
    id = nanoid(36),
    wrapperId,
    minLabel,
    maxLabel,
    minValue,
    maxValue,
    facetName,
    initialMinValue,
    initialMaxValue,
    availablePreselects,
    logarithmic = "false",
    maxPos = "100",
    delayedActionMillis,
    showDefaultPlaceholders = "true",
    focusFloatsBothLabels = "false",
    hideSlider = "false",
    inputPrefixIcon = "",
    inputPrefixText = "",
    inputSuffixIcon = "",
    inputSuffixText = "",
  }: Props = $props();

  const sliderTypes = toSliderTypesFrom(minValue, maxValue, maxPos, logarithmic);
  const sliderHost = new SliderHost(sliderTypes);

  let numberInputWrapper: HTMLDivElement | undefined = $state();
  let numberInputMinContainer: HTMLDivElement | undefined = $state();
  let numberInputMin: HTMLInputElement | undefined = $state();
  let numberInputMinLabel: HTMLLabelElement | undefined = $state();
  let numberInputMaxContainer: HTMLDivElement | undefined = $state();
  let numberInputMax: HTMLInputElement | undefined = $state();
  let numberInputMaxLabel: HTMLLabelElement | undefined = $state();
  let rangeInputWrapper: HTMLDivElement | undefined = $state();
  let inverseLeft: HTMLDivElement | undefined = $state();
  let range: HTMLDivElement | undefined = $state();
  let inverseRight: HTMLDivElement | undefined = $state();
  let thumbMin: HTMLSpanElement | undefined = $state();
  let thumbMax: HTMLSpanElement | undefined = $state();
  let rangeInputMin: HTMLInputElement | undefined = $state();
  let rangeInputMax: HTMLInputElement | undefined = $state();

  function toSliderTypesFrom(minValue: string, maxValue: string, maxPos: string, logarithmic: string) {
    const minValueNumber = Number(minValue) || 0;
    const maxValueNumber = Number(maxValue) || 0;
    const maxPosNumber = Number(maxPos) || 0;
    const isLogarithmic = logarithmic === "true";
    return {
      minVal: minValueNumber,
      maxVal: maxValueNumber,
      maxPos: maxPosNumber,
      logarithmic: isLogarithmic,
    } as SliderTypes;
  }

  function isShowDefaultPlaceholders() {
    return showDefaultPlaceholders === "true";
  }

  function isFocusFloatsBothLabels() {
    return focusFloatsBothLabels === "true";
  }

  function hasPrefix() {
    return inputPrefixIcon || inputPrefixText;
  }

  function hasSuffix() {
    return inputSuffixIcon || inputSuffixText;
  }

  function isSliderHidden() {
    return hideSlider === "true";
  }

  onMount(() => {
    if (isFocusFloatsBothLabels()) {
      numberInputMin?.addEventListener("focus", () => {
        if (numberInputWrapper) numberInputWrapper.classList.toggle("pl_input--field-has-focus");
      });
      numberInputMin?.addEventListener("blur", () => {
        if (numberInputWrapper) numberInputWrapper.classList.toggle("pl_input--field-has-focus");
      });
      numberInputMax?.addEventListener("blur", () => {
        if (numberInputWrapper) numberInputWrapper.classList.toggle("pl_input--field-has-focus");
      });
      numberInputMax?.addEventListener("blur", () => {
        if (numberInputWrapper) numberInputWrapper.classList.toggle("pl_input--field-has-focus");
      });
    }
    if (
      numberInputWrapper &&
      numberInputMinContainer &&
      numberInputMin &&
      numberInputMinLabel &&
      numberInputMaxContainer &&
      numberInputMax &&
      numberInputMaxLabel &&
      rangeInputWrapper &&
      inverseLeft &&
      range &&
      inverseRight &&
      thumbMin &&
      thumbMax &&
      rangeInputMin &&
      rangeInputMax
    ) {
      const sliderElement: SliderElement = new SliderElement(
        id,
        numberInputWrapper,
        numberInputMinContainer,
        numberInputMin,
        numberInputMinLabel,
        numberInputMaxContainer,
        numberInputMax,
        numberInputMaxLabel,
        rangeInputWrapper,
        inverseLeft,
        range,
        inverseRight,
        thumbMin,
        thumbMax,
        rangeInputMin,
        rangeInputMax,
      );
      registerListeners(sliderHost, sliderElement);
    }
  });
</script>

<div id={wrapperId} class="heureka_slider__wrapper">
  <div
    class="heureka_slider{isSliderHidden() ? ' heureka_slider--deactivated' : ''}"
    {id}
    data-slider-min-label={minLabel}
    data-slider-max-label={maxLabel}
    data-slider-min-val={minValue}
    data-slider-max-val={maxValue}
    data-facet-name={facetName}
    data-heureka-slider-initial-min-value={initialMinValue}
    data-heureka-slider-initial-max-value={initialMaxValue}
    data-heureka-slider-available-preselects={availablePreselects}
    data-slider-logarithmic={logarithmic}
    data-delayed-action-millis={delayedActionMillis}
    data-slider-show-default-placeholders={showDefaultPlaceholders}
    data-slider-focus-floats-both-labels={focusFloatsBothLabels}
    data-slider-input-prefix-icon={inputPrefixIcon}
    data-slider-input-prefix-text={inputPrefixText}
    data-slider-input-suffix-icon={inputSuffixIcon}
    data-slider-input-suffix-text={inputSuffixText}
    data-slider-max-pos={maxPos}
  >
    <div
      class="heureka_slider__input-wrapper pl_input--floating-label{isShowDefaultPlaceholders()
        ? ''
        : ' pl_input--hide-placeholders'}"
      bind:this={numberInputWrapper}
    >
      <div
        class="pl_input js-pl_input{hasPrefix() ? ' pl_input__prefix' : ''}{hasSuffix() ? ' pl_input__suffix' : ''}"
        bind:this={numberInputMinContainer}
      >
        <input
          id="{id}__minInput"
          class="pl_input__field"
          type="number"
          max={sliderHost.maxVal.toString()}
          placeholder={sliderHost.minVal.toString()}
          bind:this={numberInputMin}
        />
        <label class="pl_label js-pl_label" for="{id}__minInput" bind:this={numberInputMinLabel}>{minLabel}</label>
        {#if inputSuffixIcon}
          <SliderIcon isPrefix={false} inputIcon={inputSuffixIcon}></SliderIcon>
        {/if}
        {#if inputPrefixIcon}
          <SliderIcon isPrefix={true} inputIcon={inputPrefixIcon}></SliderIcon>
        {/if}
        {#if inputPrefixText}
          <SliderInputFieldText isPrefix={true} text={inputPrefixText}></SliderInputFieldText>
        {/if}
        {#if inputSuffixText}
          <SliderInputFieldText isPrefix={false} text={inputSuffixText}></SliderInputFieldText>
        {/if}
      </div>
      <div
        class="pl_input js-pl_input{hasPrefix() ? ' pl_input__prefix' : ''}{hasSuffix() ? ' pl_input__suffix' : ''}"
        bind:this={numberInputMaxContainer}
      >
        <input
          id="{id}__maxInput"
          class="pl_input__field"
          type="number"
          min={sliderHost.minVal.toString()}
          placeholder={sliderHost.maxVal.toString()}
          bind:this={numberInputMax}
        />
        <label class="pl_label js-pl_label" for="{id}__maxInput" bind:this={numberInputMaxLabel}>{maxLabel}</label>
        {#if inputPrefixIcon}
          <SliderIcon isPrefix={true} inputIcon={inputPrefixIcon}></SliderIcon>
        {/if}
        {#if inputSuffixIcon}
          <SliderIcon isPrefix={false} inputIcon={inputSuffixIcon}></SliderIcon>
        {/if}
        {#if inputPrefixText}
          <SliderInputFieldText isPrefix={true} text={inputPrefixText}></SliderInputFieldText>
        {/if}
        {#if inputSuffixText}
          <SliderInputFieldText isPrefix={false} text={inputSuffixText}></SliderInputFieldText>
        {/if}
      </div>
    </div>
    <div class="heureka_slider__range-wrapper" bind:this={rangeInputWrapper}>
      <div class="heureka_slider__inverse-left" bind:this={inverseLeft}>
        <div class="heureka_slider__inverse__inner"></div>
      </div>
      <div class="heureka_slider__range" bind:this={range}>
        <div class="heureka_slider__range__inner"></div>
      </div>
      <div class="heureka_slider__inverse-right" bind:this={inverseRight}>
        <div class="heureka_slider__inverse__inner"></div>
      </div>
      <input
        class="heureka_slider__range--min"
        type="range"
        tabindex="0"
        min={sliderHost.minPos.toString()}
        max={sliderHost.maxPos.toString()}
        value={sliderHost.minPos.toString()}
        bind:this={rangeInputMin}
      />
      <input
        class="heureka_slider__range--max"
        type="range"
        tabindex="0"
        min={sliderHost.minPos.toString()}
        max={sliderHost.maxPos.toString()}
        value={sliderHost.maxPos.toString()}
        bind:this={rangeInputMax}
      />
      <span class="heureka_slider__thumb-min" bind:this={thumbMin}></span>
      <span class="heureka_slider__thumb-max" bind:this={thumbMax}></span>
    </div>
  </div>
</div>
