export default function inputDefault(node: HTMLInputElement) {
  switch (node.type) {
    case "radio":
    case "checkbox":
      node.defaultChecked = node.checked;
    /*            */
    case "text":
      node.defaultValue = node.value;
      return;
  }
}
